import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import markets from '../markets'
import _ from 'lodash'
import qs from 'query-string'

import moment from 'moment'
import 'moment/locale/ko'

import 'animate.css'

const comma = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); }

const Tabs = styled.nav`
  flex: 1 1 100%; text-align: center;
  position: relative; box-sizing: border-box;
  & > a {
    display: inline-block;
    border-radius: 10px; text-align: center; transition: all 0.3s;
    padding: 0.35rem 0.75rem;
    &.active {
      background: white; color: black; opacity: 1;
      strong { color: black; }
    }
  }
`
Tabs.Group = styled.div`
  display: flex; padding: 1rem 0;
`

const Nav = styled.nav`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; justify-content: center;
  padding: 1rem 0.25rem;
  & > a {
    max-width: 50%; min-width: 110px; padding: 0.5rem 1rem;
    border-radius: 10px; text-align: center; transition: all 0.3s;
    margin: 0.4rem; background: #0b0c11; opacity: 0.8;
    small { color: #929292; }
    strong { color: white; }
    &.active {
      background: white; color: black; opacity: 1;
      strong { color: black; }
    }
    &:hover { opacity: 1; }
  }
`

const Control = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem; 
  & > input {
    display: block; width: 100%; box-sizing: border-box; outline: none;
    padding: 0.5rem; border-radius: 0.35rem; border: 0;
  }
`

const Item = styled.a`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; transition: all 0.3s;
  background: #0b0c11; border-radius: 0.35rem;
  margin: 0.5rem 0; padding: 0.25rem 0;
  border: 3px solid #0b0c11; font-size: 0.75em;
  & > div { flex: 1 1 100%; padding: 0.35em; }
  &.yellow { border: 3px solid yellow;  }
  &.white { border: 3px solid white; }
  & small { color: #929292; }
  & .symbol {
    position: relative; box-sizing: border-box;
    margin: 0.25rem; display: flex; align-items: center; justify-content: center;
    width: 25px; height: 25px;
    background: white; border-radius: 50%;
    & > img { width: 15px; height: 15px; border-radius: 50%; }
  }
  & > .pop {
    position: absolute; right: 0; top: -0.75rem;
    border-radius: 0.35rem; background: white;
    font-size: 0.9em; color: black; background: white;
    line-height: 1rem; padding: 0.25rem 0.75rem;
  }
  &.yellow > .pop{ background: yellow;  }
  &.white > .pop { background: white; }

  & .signals {
    display: flex; flex-wrap: wrap; flex: 1 1 100%;
    max-width: 100px; min-width: 100px; justify-content: flex-end;
    @media screen and (max-device-width: 680px) {
      max-width: 200px; min-width: 200px;
    }
    @media screen and (max-device-width: 320px) {
      max-width: 100px; min-width: 100px;
    }
  } 
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
`

// 골드크로스, 데드크로스 코인별 구성으로 체크해W기
class MobileTableWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      m15: [],
      m30: [],
      m60: [],
      m240: [],
      mode: props.pick ?'long' : 'short',
      prev: {},
      prevFt: null,
      loading: false,
      keyword: '',

      dashboard: {},

      exception: props.pick ?'all' : 'dead',
    }

    this.timer = false
    this.units = [
      { code: 'm1', text: '1분봉' },
      { code: 'm3', text: '3분봉' },
      { code: 'm15', text: '15분봉' },
      { code: 'm30', text: '30분봉' },
      { code: 'm60', text: '1시간' },
      { code: 'm240', text: '4시간' },
      { code: 'd1', text: '일봉' },
      { code: 'w1', text: 'W봉' },
    ].reverse()

    this.initialize = this.initialize.bind(this)
    this.loadCross = this.loadCross.bind(this)
    this.loadDashboard = this.loadDashboard.bind(this)
    this.getItems = this.getItems.bind(this)
    this.run = this.run.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {
    const that = this
    that.run(true)
    this.timer = window.setInterval(that.run, 5000 * 10)
  }

  // 분봉 타점맵에 대한 타임라인 데이터 불러오기
  async loadDashboard() {
    const curAt = new Date(), curMt = moment(curAt).add(-1, 'minutes')
    const at = curMt.format('YYYYMMDDHHmm')
    return await fetch(`https://api.coinrbi.com/datas/${at}`)
      .then((response) => response.json().catch(e => {}))
      .catch(e => ({}))
  }

  async run(isFirst) {
    const that = this

    const m1 = await this.loadCross('m1').catch(e => [])
    const m3 = await this.loadCross('m3').catch(e => [])
    const m15 = await this.loadCross('m15').catch(e => [])
    const m30 = await this.loadCross('m30').catch(e => [])
    const m60 = await this.loadCross('m60').catch(e => [])
    const m240 = await this.loadCross('m240').catch(e => [])
    const d1 = await this.loadCross('d1').catch(e => [])
    const w1 = await this.loadCross('w1').catch(e => [])
    const dashboard = await this.loadDashboard().catch(e => ({}))

    ReactGA.event({ category: 'AutoCross', action: `Coin AutoCross`, label: `AutoCross` })

    const next = { m1, m3, m15, m30, m60, m240, d1, w1, dashboard, loading: false }

    // 과거 데이터를 집계
    const curAt = new Date(), curMt = moment(curAt)
    const curFt = curMt.format('YYYYMMDDHHmm')
    const prevFt = that.state.prevFt

    if (!isFirst && curFt != prevFt) {
      next.prevFt = curFt
      next.prev = Object.values(that.state.prev).length <= 5
        ? { ...that.state.prev }
        : { ...Object.values(that.state.prev).filter((o, i) => i === (Object.values(that.state.prev).length -1)) }
      next.prev[curFt] = that.getItems().filter(item => !['KRW-BTC', 'KRW-ETH'].includes(item.market))
    }

    this.setState(next)
  }

  async loadCross(timing = 1) {
    return await fetch(`https://api.coinrbi.com/reports/realtime_cross.${timing}.json`)
      .then((response) => response.json().catch(e => []))
      .catch(e => {
        console.log(e.message)
        return []
      })
  }

  getItems() {
    const that = this

    // 시간 상수 구성
    const curAt = new Date(), curMt = moment(curAt)
    const hour = curMt.format('HH') * 1
    const peaks = [4,5,6,7,8,19,20,21,22,23]

    // 아이템을 재정렬해서 순위가 높은 것 기준으로 정배열 해줘야한다.
    const items = markets.map(item => {
      const combined = { ...item }
      
      combined.meta = {}
      combined.meta.w1 = (that.state.w1 || []).find(_item => _item.market === item.market) || {}
      combined.meta.d1 = (that.state.d1 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m240 = (that.state.m240 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m60 = (that.state.m60 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m30 = (that.state.m30 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m15 = (that.state.m15 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m3 = (that.state.m3 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m1 = (that.state.m1 || []).find(_item => _item.market === item.market) || {}
      
      let score_w1 = _.get(combined.meta, 'w1.rbi.macd') ? 3 : (_.get(combined.meta, 'w1.rbi.r_macd') ? -3 : 0)
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.rbi.obv') ? 3 : (_.get(combined.meta, 'w1.rbi.r_obv') ? -3 : 0))
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.fired') ? 2 : (_.get(combined.meta, 'w1.waterfall') ? -2 : 0))
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.rbi.wr') ? 1 : (_.get(combined.meta, 'w1.rbi.wr') ? -1 : 0))
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.rbi.rsi') ? 1 : (_.get(combined.meta, 'w1.rbi.rsi') ? -1 : 0))
      let score_d1 = _.get(combined.meta, 'd1.rbi.macd') ? 2 : (_.get(combined.meta, 'd1.rbi.r_macd') ? -2 : 0)
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.rbi.obv') ? 2 : (_.get(combined.meta, 'd1.rbi.r_obv') ? -2 : 0))
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.fired') ? 3 : (_.get(combined.meta, 'd1.waterfall') ? -3 : 0))
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.rbi.wr') ? 0.5 : (_.get(combined.meta, 'd1.rbi.r_wr') ? -0.5 : 0))
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.rbi.rsi') ? 0.5 : (_.get(combined.meta, 'd1.rbi.r_rsi') ? -0.5 : 0))
      let score_m240 = _.get(combined.meta, 'm240.rbi.macd') ? 1 : (_.get(combined.meta, 'm240.rbi.r_macd') ? -1 : 0)
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.rbi.obv') ? 1 : (_.get(combined.meta, 'm240.rbi.r_obv') ? -1 : 0))
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.fired') ? 2 : (_.get(combined.meta, 'm240.waterfall') ? -2 : 0))
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.rbi.wr') ? 0.25 : (_.get(combined.meta, 'm240.rbi.r_wr') ? -0.25 : 0))
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.rbi.rsi') ? 0.25 : (_.get(combined.meta, 'm240.rbi.r_rsi') ? -0.25 : 0))
      let score_m60 = _.get(combined.meta, 'm60.rbi.macd') ? 0.5 : (_.get(combined.meta, 'm60.rbi.r_macd') ? -0.5 : 0)
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.rbi.obv') ? 0.5 : (_.get(combined.meta, 'm60.rbi.r_obv') ? -0.5 : 0))
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.fired') ? 1 : (_.get(combined.meta, 'm60.waterfall') ? -1 : 0))
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.rbi.wr') ? 0.25 : (_.get(combined.meta, 'm60.rbi.r_wr') ? -0.25 : 0))
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.rbi.rsi') ? 0.25 : (_.get(combined.meta, 'm60.rbi.r_sri') ? -0.25 : 0))
      let score_m30 = _.get(combined.meta, 'm30.rbi.macd') ? 0.25 : (_.get(combined.meta, 'm30.rbi.r_macd') ? -0.25 : 0)
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.rbi.obv') ? 0.25 : (_.get(combined.meta, 'm30.rbi.r_obv') ? -0.25 : 0))
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.fired') ? 0.5 : (_.get(combined.meta, 'm30.waterfall') ? -0.5 : 0))
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.rbi.wr') ? 0.5 : (_.get(combined.meta, 'm30.rbi.r_wr') ? -0.5 : 0))
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.rbi.rsi') ? 0.5 : (_.get(combined.meta, 'm30.rbi.r_rsi') ? -0.5 : 0))
      let score_m15 = _.get(combined.meta, 'm15.rbi.macd') ? 0.125 : (_.get(combined.meta, 'm15.rbi.r_macd') ? -0.125 : 0)
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.rbi.obv') ? 0.125 : (_.get(combined.meta, 'm15.rbi.r_obv') ? -0.125 : 0))
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.fired') ? 0.125 : (_.get(combined.meta, 'm15.waterfall') ? -0.125 : 0))
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.rbi.wr') ? 0.1 : (_.get(combined.meta, 'm15.rbi.r_wr') ? -0.1 : 0))
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.rbi.rsi') ? 0.1 : (_.get(combined.meta, 'm15.rbi.r_rsi') ? -1 : 0))
      let score_m3 = _.get(combined.meta, 'm3.rbi.macd') ? 0.075 : (_.get(combined.meta, 'm3.rbi.r_macd') ? -0.075 : 0)
      score_m3 = score_m3 + (_.get(combined.meta, 'm3.rbi.obv') ? 0.075 : (_.get(combined.meta, 'm3.rbi.r_obv') ? -0.075 : 0))
      score_m3 = score_m3 + (_.get(combined.meta, 'm3.fired') ? 0.125 : (_.get(combined.meta, 'm3.waterfall') ? -0.125 : 0))
      let score_m1 = _.get(combined.meta, 'm1.rbi.macd') ? 0.0035 : (_.get(combined.meta, 'm1.rbi.r_macd') ? -0.0035 : 0)
      score_m1 = score_m1 + (_.get(combined.meta, 'm1.rbi.obv') ? 0.0035 : (_.get(combined.meta, 'm1.rbi.r_obv') ? -0.0035 : 0))
      score_m1 = score_m1 + (_.get(combined.meta, 'm1.fired') ? 0.075 : (_.get(combined.meta, 'm1.waterfall') ? -0.075 : 0))

      combined.score = score_w1 + score_d1 + score_m240 + score_m60 + score_m30 + score_m15
        + (peaks.includes(hour) ? (score_m3 + score_m1) * 49 : (score_m3 + score_m1))
      
      return combined
    })

    items.sort((a, b) => {
      if (that.state.mode === 'long') {
        return a.score < b.score ? 1 : -1
      } else {
        return a.score < b.score ? -1 : 1
      }
    })

    return items
  }

  render() {
    if (this.state.loading) { return null }

    const that = this
    const { pick } = this.props

    const search = window.location.search ? qs.parse(window.location.search) : {}

    let items = that.getItems()

    // 픽모드인경우 해당코인만 보인다
    if (pick && pick.market) { items = items.filter(item => item.market === pick.market) }

    // 코인리스트
    return (
      <>
        {!pick ? (
            <Nav>
              <a
                href="#longMode"
                className={that.state.mode === 'long' ? 'active' : ''}
                onClick={e => {
                  return [e.preventDefault(), that.setState({ mode: 'long', prevFt: null, prev: [] })]
                }}
              >
                <div><strong><span style={{ fontWeight: '100' }}>🔥</span> 상방·추격</strong></div>
                <div><small><strong>롱</strong> Hot Price</small></div>
              </a>
              <a
                href="#shortMode"
                className={that.state.mode === 'short' ? 'active' : ''}
                onClick={e => {
                  return [e.preventDefault(), that.setState({ mode: 'short', prevFt: null, prev: [] })]
                }}
              >
                <div><strong><span style={{ fontWeight: '100' }}>💧</span>  하방·저가</strong></div>
                <div><small><strong>숏</strong> Low Price</small></div>
              </a>
            </Nav>
        ) : null}
        
        {!pick ? (
          <Tabs.Group>
            <Tabs>
              <a
                href="#all"
                onClick={e => [e.preventDefault(), that.setState({ exception: 'all' })]}
                className={that.state.exception === 'all' ? 'active' : ''}
              >
                전체
              </a>
              <a
                href="#usa"
                onClick={e => [e.preventDefault(), that.setState({ exception: 'dead' })]}
                className={that.state.exception === 'dead' ? 'active' : ''}
              >
                매수<small style={{ marginLeft: '0.25rem' }}>Up</small>
              </a>
              <a
                href="#asia"
                onClick={e => [e.preventDefault(), that.setState({ exception: 'golden' })]}
                className={that.state.exception === 'golden' ? 'active' : ''}
              >
                매도<small style={{ marginLeft: '0.25rem' }}>Down</small>
              </a>
            </Tabs>
          </Tabs.Group>
        ) : null}

        {!pick ? (
          <Control>
            <input
              type="text"
              defaultValue={that.state.keyword}
              placeholder={`ex) XRP,이더,Ethe`}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  return that.setState({ keyword: e.target.value })
                }
              }}
            />
          </Control>
        ) : null}

        <div style={{ display: 'flex' }}>
          <Item.Group style={{ flex: '1 1 50%', padding: '0 0.35rem' }}>
            {[...items.filter(item => ['KRW-BTC', 'KRW-ETH'].includes(item.market)),
              ...items.filter(item => !['KRW-BTC', 'KRW-ETH'].includes(item.market))]
              .map(item => {
                if (!that.state.keyword) { return item }
                const name = item.market.replace('KRW-', '')
                const keyword = that.state.keyword || ''
                const keys = keyword.split(',')
                return keys.map(k => k.trim()).map((k) => {
                  if (!k) { return false }
                  if (item.korean_name.indexOf(k) >= 0) { return true }
                  if (item.english_name.indexOf(k) >= 0) { return true }
                  if (name.indexOf(k) >= 0) { return true }
                  return false
                }).includes(true) ? item : { ...item, hide: true }
              })

              // 필터 추가
              .map(item => {
                let next = item
                const meta = item.meta || {}
                if (that.state.exception !== 'all') {
                  // 데드구간이 하나라도 껴있으면 제외
                  if (!next.hide && that.state.exception === 'dead'
                    && (
                      !_.get(meta, 'w1.rbi.r_macd') &&
                      !_.get(meta, 'd1.rbi.r_macd') &&
                      !_.get(meta, 'm240.rbi.r_macd') &&
                      !_.get(meta, 'm60.rbi.r_macd') &&
                      !_.get(meta, 'm30.rbi.r_macd') &&
                      !_.get(meta, 'm15.rbi.r_macd') &&
                      !_.get(meta, 'm3.rbi.r_macd') &&
                      !_.get(meta, 'm1.rbi.r_macd')
                    )
                  ) { next = item }
                  else if (!next.hide && that.state.exception === 'golden'
                    && (
                      !_.get(meta, 'w1.rbi.macd') &&
                      !_.get(meta, 'd1.rbi.macd') &&
                      !_.get(meta, 'm240.rbi.macd') &&
                      !_.get(meta, 'm60.rbi.macd') &&
                      !_.get(meta, 'm30.rbi.macd') &&
                      !_.get(meta, 'm15.rbi.macd') &&
                      !_.get(meta, 'm3.rbi.macd') &&
                      !_.get(meta, 'm1.rbi.macd')
                    )
                  ) { next = item }
                  else { next = { ...item, hide: true } }
                }
                return next
              })

              .map((item, coinIdx) => {
              const name = item.market.replace('KRW-', '')
              const meta = item.meta || {}
              
              // 방향 알고리즘
              const minLowPrice = Math.min(...(_.get(item, 'meta.m240.prices') || [])) || 0
              const minFirstPrice = Math.min(...(_.get(item, 'meta.m30.prices') || [])) || 0
              const currentPrice = _.get(item, 'meta.m1.prices[0]') || 0
              const direction = (currentPrice <= (minLowPrice * 1.03))
                ? 'yellow'
                : ((currentPrice <= (minFirstPrice * 1.015)) ? 'white' : '')

              return (
                <Item
                  key={`coin_${coinIdx}`}
                  className={`${direction} animate__animated animate__fadeInDown`}
                  style={{ 'animationDelay': `${coinIdx * 0.01}s`, display: item.hide ? 'none' : null, justifyContent: 'space-between' }}
                  href={`/UPBIT:${name}KRW`}
                  onClick={e => [window.localStorage.setItem('mode', 'trade'), ReactGA.event({ category: 'View', action: `Go Upbit Review`, label: `${name} Upbit Table` })]}
                >
                  {((item) => {
                    const datas = _.get(that, 'state.dashboard.meterials') || []
                    const part = datas.find(data => data.market === item.market) || {}
                    if (!part || !part.market) { return null }

                    const totalTradeAmount = part.totalTradeAmount || 0
                    const appealTotal = totalTradeAmount >= 100000000
                      ? `${comma(Math.round(totalTradeAmount*100/100000000)/100)}억원`
                      :`${comma(Math.round(totalTradeAmount*100/10000000)/100)}천만원`
                    const gap = part.prices[0] !== part.prices[1]

                    const firstPrice = comma(part.prices[1] || 0)
                    const lastPrice = comma(part.prices[0] || 0)

                    const direction = part.score > part.r_score
                      ? `🚅` : (part.score < part.r_score ? `🏃` : `🤔`)

                    return (
                      <div className="pop">
                        {gap ? (
                          <>
                            <small style={{ marginRight: '0.35rem' }}>{firstPrice}</small>
                            <small style={{ marginRight: '0.35rem' }}>→</small>
                            <small style={{ marginRight: '0.35rem' }}>{lastPrice} 원</small>
                          </>
                        ) : null}
                        {!gap ? (
                          <>
                            <small style={{ marginRight: '0.35rem' }}>{lastPrice} 원</small>
                          </>
                        ) : null}
                        <strong>{appealTotal} {direction}</strong>
                      </div>
                    )
                  })(item)}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '55px', minWidth: '35px' }}>
                    <div style={{ textAlign: 'center' }}>
                      <div
                        className="symbol"
                        key={`symbol_${coinIdx}`}
                      >
                        <img
                          src={`https://api.coinrbi.com/symbols/${name}.png`}
                          alt={`${item.korean_name}, ${name}`}
                          title={`${item.korean_name}, ${name}`}
                        />
                      </div>
                      <div style={{ lineHeight: '1.1em' }}>
                        <small style={{ fontSize: '0.8em', color: coinIdx < 20 ? 'yellow' : 'inherit' }}>{coinIdx+1}</small>
                          {(() => {
                            const curAt = new Date(), curMt = moment(curAt)
                            const prevFt = curMt.format('YYYYMMDDHHmm')
                            const prevItems = that.state.prev[prevFt] || []
                            const prevItem = prevItems.find(pItem => pItem.market === item.market)
                            if (!prevItem) { return null }
                            const prevIdx = prevItems.findIndex(pItem => pItem.market === item.market)
                            if (prevIdx < 0) { return null }
                            return (coinIdx - prevIdx) > 0 ? <small style={{ fontSize: '0.8em', marginLeft: '0.25rem' }}>-{coinIdx - prevIdx}</small> : ((coinIdx - prevIdx) < 0 ? <small style={{ fontSize: '0.8em', marginLeft: '0.25rem' }}>+{Math.abs(coinIdx - prevIdx)}</small> :  null)
                          })()}
                      </div>
                    </div>
                  </div>
                  <div style={{ minWidth: '40px', maxWidth: '90px' }}>
                    <div style={{ lineHeight: '1.1em', marginBottom: '0.25rem', textOverflow: 'ellipsis', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}><small style={{ fontWeight: '100', color: '#929394', fontSize: '0.8em' }}>{item.market}</small></div>
                    <div style={{ lineHeight: '1.1em', textOverflow: 'ellipsis', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      <strong style={{ fontSize: '0.8em' }}>{item.korean_name}</strong>
                    </div>
                    <div style={{ lineHeight: '1.1em', textOverflow: 'ellipsis', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}><small style={{ fontWeight: '100', color: '#929394', fontSize: '0.8em' }}>{item.english_name}</small></div>
                  </div>
                  <div style={{ flex: '1 0 0', minWidth: 0 }}></div>
                  <div className={"signals"}>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>W</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'w1.rbi.macd') ? '🟢' : (_.get(meta, 'w1.rbi.r_macd') ? '🔴' : (_.get(meta, 'w1.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>D</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'd1.rbi.macd') ? '🟢' : (_.get(meta, 'd1.rbi.r_macd') ? '🔴' : (_.get(meta, 'd1.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>4H</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm240.rbi.macd') ? '🟢' : (_.get(meta, 'm240.rbi.r_macd') ? '🔴' : (_.get(meta, 'm240.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>1H</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm60.rbi.macd') ? '🟢' : (_.get(meta, 'm60.rbi.r_macd') ? '🔴' : (_.get(meta, 'm60.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>30m</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm30.rbi.macd') ? '🟢' : (_.get(meta, 'm30.rbi.r_macd') ? '🔴' : (_.get(meta, 'm30.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>15m</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm15.rbi.macd') ? '🟢' : (_.get(meta, 'm15.rbi.r_macd') ? '🔴' : (_.get(meta, 'm15.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>3m</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm3.rbi.macd') ? '🟢' : (_.get(meta, 'm3.rbi.r_macd') ? '🔴' : (_.get(meta, 'm3.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                    <div style={{ maxWidth: '25px', minWidth: '25px', textAlign: 'center' }}>
                      <div><small style={{ fontSize: '0.8em' }}>1m</small></div>
                      <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm1.rbi.macd') ? '🟢' : (_.get(meta, 'm1.rbi.r_macd') ? '🔴' : (_.get(meta, 'm1.rbi.obv') ? '⚪' : '⚫'))}</div>
                    </div>
                  </div>
                </Item>
              )
            })}
          </Item.Group>
        </div>
      </>
    )
  }

  componentWillUnmount() {
    if (this.timer) { window.clearInterval(this.timer) }
  }
}

MobileTableWidget.propTypes = {
  handleCoin: PropTypes.func
}

MobileTableWidget.defaultProps = {
  handleCoin: () => {}
}

export default MobileTableWidget