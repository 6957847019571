const tags = [
  { market: 'KRW-BTC', name: 'BTC', tags: '선물있음,바이낸스USDT,코인베이스USD,채굴,미국' },
  { market: 'KRW-ETH', name: 'ETH', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,채굴,플랫폼,유럽' },
  { market: 'KRW-NEO', name: 'NEO', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,재정,디파이,아시아,중국' },
  { market: 'KRW-MTL', name: 'MTL', tags: '선물있음,바이낸스USDT,바이낸스BTC,기술,플랫폼,미국' },
  { market: 'KRW-LTC', name: 'LTC', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,채굴,아시아,중국' },
  { market: 'KRW-XRP', name: 'XRP', tags: '선물있음,바이낸스USDT,바이낸스BTC,재정,미국' },
  { market: 'KRW-ETC', name: 'ETC', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,채굴,유럽' },
  { market: 'KRW-OMG', name: 'OMG', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,기술,금융,아시아,싱가포르' },
  { market: 'KRW-SNT', name: 'SNT', tags: '바이낸스BTC,이더리움,토큰,디앱,아시아,싱가포르' },
  { market: 'KRW-WAVES', name: 'WAVES', tags: '선물있음,바이낸스USDT,바이낸스BTC,기술,플랫폼,우크라이나,러시아' },
  { market: 'KRW-XEM', name: 'XEM', tags: '선물있음,바이낸스USDT,바이낸스BTC,기술,아시아,싱가포르' },
  { market: 'KRW-QTUM', name: 'QTUM', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,아시아,중국' },
  { market: 'KRW-LSK', name: 'LSK', tags: '바이낸스BTC,기술,플랫폼,디앱,독일,유럽' },
  { market: 'KRW-STEEM', name: 'STEEM', tags: '바이낸스BTC,플랫폼,미국' },
  { market: 'KRW-XLM', name: 'XLM', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,플랫폼,미국' },
  { market: 'KRW-ARDR', name: 'ARDR', tags: '바이낸스USDT,바이낸스BTC,기술,유럽,불가리아' },
  { market: 'KRW-ARK', name: 'ARK', tags: '바이낸스BTC,기술,플랫폼,디앱,유럽,프랑스' },
  { market: 'KRW-STORJ', name: 'STORJ', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,기술,미국' },
  { market: 'KRW-GRS', name: 'GRS', tags: '바이낸스BTC,채굴,기술,유럽,네덜란드,미국' },
  { market: 'KRW-REP', name: 'REP', tags: '바이낸스BTC,코인베이스USD,이더리움,토큰,미국' },
  { market: 'KRW-ADA', name: 'ADA', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,플랫폼,미국' },
  { market: 'KRW-SBD', name: 'SBD', tags: '플랫폼,미국' },
  { market: 'KRW-POWR', name: 'POWR', tags: '바이낸스BTC,이더리움,토큰,친환경,호주' },
  { market: 'KRW-BTG', name: 'BTG', tags: '바이낸스BTC,채굴,아시아,중국' },
  { market: 'KRW-ICX', name: 'ICX', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,아시아,대한민국' },
  { market: 'KRW-EOS', name: 'EOS', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,플랫폼,미국,유럽,케이멘,영국' },
  { market: 'KRW-TRX', name: 'TRX', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,아시아,중국' },
  { market: 'KRW-SC', name: 'SC', tags: '선물있음,바이낸스USDT,바이낸스BTC,채굴,플랫폼,아시아,중국' },
  { market: 'KRW-ONT', name: 'ONT', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,아시아,중국' },
  { market: 'KRW-ZIL', name: 'ZIL', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,아시아,싱가포르,중국' },
  { market: 'KRW-POLY', name: 'POLY', tags: '바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,캐나다' },
  { market: 'KRW-ZRX', name: 'ZRX', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,DEX,스테이킹,미국' },
  { market: 'KRW-LOOM', name: 'LOOM', tags: '바이낸스BTC,코인베이스USD,이더리움,토큰,게임,태국' },
  { market: 'KRW-BCH', name: 'BCH', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,채굴,아시아,중국' },
  { market: 'KRW-BAT', name: 'BAT', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,미국' },
  { market: 'KRW-IOST', name: 'IOST', tags: '선물있음,바이낸스USDT,바이낸스BTC,기술,아시아,중국' },
  { market: 'KRW-RFR', name: 'RFR', tags: '이더리움,토큰,게임,호주' },
  { market: 'KRW-CVC', name: 'CVC', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,기술,미국' },
  { market: 'KRW-IQ', name: 'IQ', tags: '이오스,토큰,미국' },
  { market: 'KRW-IOTA', name: 'IOTA', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,독일,유럽,노르웨이' },
  { market: 'KRW-MFT', name: 'MFT', tags: '바이낸스USDT,이더리움,토큰,미국' },
  { market: 'KRW-ONG', name: 'ONG', tags: '바이낸스USDT,바이낸스BTC,온톨로지,토큰,아시아,중국' },
  { market: 'KRW-GAS', name: 'GAS', tags: '바이낸스BTC,네오,토큰,아시아,중국' },
  { market: 'KRW-UPP', name: 'UPP', tags: '이더리움,토큰,아시아,싱가포르,아시아,대한민국' },
  { market: 'KRW-ELF', name: 'ELF', tags: '바이낸스BTC,플랫폼,아시아,중국' },
  { market: 'KRW-KNC', name: 'KNC', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,DEX,아시아,싱가포르,베트남' },
  { market: 'KRW-BSV', name: 'BSV', tags: '채굴,호주' },
  { market: 'KRW-THETA', name: 'THETA', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,미국' },
  { market: 'KRW-QKC', name: 'QKC', tags: '바이낸스BTC,플랫폼,아시아,중국' },
  { market: 'KRW-BTT', name: 'BTT', tags: '선물있음,바이낸스USDT,트론,토큰,아시아,중국' },
  { market: 'KRW-MOC', name: 'MOC', tags: '루니버스,토큰,메타버스,NFT,아시아,대한민국' },
  { market: 'KRW-ENJ', name: 'ENJ', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,아시아,싱가포르' },
  { market: 'KRW-TFUEL', name: 'TFUEL', tags: '바이낸스USDT,바이낸스BTC,세타토큰,토큰,미국' },
  { market: 'KRW-MANA', name: 'MANA', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,메타버스,NFT,미국' },
  { market: 'KRW-ANKR', name: 'ANKR', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,미국' },
  { market: 'KRW-AERGO', name: 'AERGO', tags: '이더리움,토큰,아시아,대한민국' },
  { market: 'KRW-ATOM', name: 'ATOM', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,플랫폼,미국' },
  { market: 'KRW-TT', name: 'TT', tags: '플랫폼,대만' },
  { market: 'KRW-CRE', name: 'CRE', tags: '이더리움,토큰,아시아,대한민국' },
  { market: 'KRW-MBL', name: 'MBL', tags: '바이낸스USDT,온톨로지,토큰,아시아,대한민국' },
  { market: 'KRW-WAXP', name: 'WAXP', tags: '바이낸스USDT,바이낸스BTC,플랫폼,게임,미국' },
  { market: 'KRW-HBAR', name: 'HBAR', tags: '선물있음,바이낸스USDT,바이낸스BTC,기술,솔루션,미국' },
  { market: 'KRW-MED', name: 'MED', tags: '코스모스,토큰,아시아,대한민국' },
  { market: 'KRW-MLK', name: 'MLK', tags: '루니버스,토큰,아시아,대한민국' },
  { market: 'KRW-STPT', name: 'STPT', tags: '바이낸스USDT,바이낸스BTC,이더리움,토큰,아시아,중국,아시아,대한민국' },
  { market: 'KRW-ORBS', name: 'ORBS', tags: '이더리움,토큰,이스라엘,아시아,대한민국' },
  { market: 'KRW-VET', name: 'VET', tags: '선물있음,바이낸스USDT,바이낸스BTC,플랫폼,아시아,중국' },
  { market: 'KRW-CHZ', name: 'CHZ', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,스포츠,NFT,몰타,아시아,미국,유럽,대한민국' },
  { market: 'KRW-STMX', name: 'STMX', tags: '선물있음,바이낸스USDT,바이낸스BTC,이더리움,토큰,미국' },
  { market: 'KRW-DKA', name: 'DKA', tags: '이더리움,토큰,아시아,대한민국' },
  { market: 'KRW-HIVE', name: 'HIVE', tags: '바이낸스USDT,바이낸스BTC,플랫폼,미국' },
  { market: 'KRW-KAVA', name: 'KAVA', tags: '선물있음,바이낸스USDT,바이낸스BTC,코스모스,토큰,디파이,미국' },
  { market: 'KRW-AHT', name: 'AHT', tags: '루니버스,토큰,아시아,대한민국,아시아,중국' },
  { market: 'KRW-LINK', name: 'LINK', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,미국' },
  { market: 'KRW-XTZ', name: 'XTZ', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,플랫폼,프랑스,미국' },
  { market: 'KRW-BORA', name: 'BORA', tags: '이더리움,토큰,아시아,대한민국' },
  { market: 'KRW-JST', name: 'JST', tags: '바이낸스USDT,바이낸스BTC,트론,토큰,아시아,중국' },
  { market: 'KRW-CRO', name: 'CRO', tags: '코인베이스USD,코스모스,토큰,스위스,유럽' },
  { market: 'KRW-TON', name: 'TON', tags: '이더리움,토큰,러시아,독일' },
  { market: 'KRW-SXP', name: 'SXP', tags: '선물있음,바이낸스USDT,바이낸스BTC,이더리움,토큰,유럽,영국,에스토니아' },
  { market: 'KRW-HUNT', name: 'HUNT', tags: '이더리움,토큰,미국,캐나다,영국' },
  { market: 'KRW-PLA', name: 'PLA', tags: '코인베이스USD,이더리움,토큰,게임,아시아,대한민국' },
  { market: 'KRW-DOT', name: 'DOT', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,플랫폼,유럽,독일,프랑스' },
  { market: 'KRW-SRM', name: 'SRM', tags: '선물있음,바이낸스USDT,바이낸스BTC,이더리움,토큰,DEX,미국' },
  { market: 'KRW-MVL', name: 'MVL', tags: '이더리움,토큰,아시아,싱가포르,아시아,대한민국' },
  { market: 'KRW-STRAX', name: 'STRAX', tags: '바이낸스USDT,바이낸스BTC,플랫폼,게임,미국,아시아,중국' },
  { market: 'KRW-AQT', name: 'AQT', tags: '이더리움,토큰,세이셀,아시아,대한민국' },
  { market: 'KRW-GLM', name: 'GLM', tags: '바이낸스BTC,이더리움,토큰,유럽,스위스' },
  { market: 'KRW-SSX', name: 'SSX', tags: '클레이튼,토큰,아시아,대한민국' },
  { market: 'KRW-META', name: 'META', tags: '플랫폼,케이멘,아시아,대한민국' },
  { market: 'KRW-FCT2', name: 'FCT2', tags: '이더리움,토큰,아시아,싱가포르,아시아,대한민국' },
  { market: 'KRW-CBK', name: 'CBK', tags: '이더리움,토큰,아시아,싱가포르,아시아,대한민국' },
  { market: 'KRW-SAND', name: 'SAND', tags: '선물있음,바이낸스USDT,바이낸스BTC,이더리움,토큰,아르헨티나' },
  { market: 'KRW-HUM', name: 'HUM', tags: '이더리움,토큰,아시아,대한민국' },
  { market: 'KRW-DOGE', name: 'DOGE', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,채굴,재정,플랫폼,미국' },
  { market: 'KRW-STRK', name: 'STRK', tags: '이더리움,토큰,미국' },
  { market: 'KRW-PUNDIX', name: 'PUNDIX', tags: '바이낸스USDT,이더리움,토큰,아시아,싱가포르' },
  { market: 'KRW-FLOW', name: 'FLOW', tags: '바이낸스USDT,바이낸스BTC,플랫폼,NFT,미국,아시아,대한민국' },
  { market: 'KRW-DAWN', name: 'DAWN', tags: '이더리움,토큰,게임,NFT,미국' },
  { market: 'KRW-AXS', name: 'AXS', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,게임,디파이,스테이킹,아시아,베트남' },
  { market: 'KRW-STX', name: 'STX', tags: '바이낸스USDT,바이낸스BTC,플랫폼,디파이,스테이킹,미국' },
  { market: 'KRW-XEC', name: 'XEC', tags: '선물있음,바이낸스USDT,바이낸스BTC,채굴,플랫폼,유럽,프랑스' },
  { market: 'KRW-NU', name: 'NU', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,기술,미국' },
  { market: 'KRW-SOL', name: 'SOL', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,플랫폼,미국' },
  { market: 'KRW-MATIC', name: 'MATIC', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,게임,NFT,DEX,미국' },
  { market: 'KRW-AAVE', name: 'AAVE', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,DEX,디파이,스테이킹,유럽,스위스' },
  { market: 'KRW-1INCH', name: '1INCH', tags: '선물있음,바이낸스USDT,바이낸스BTC,코인베이스USD,이더리움,토큰,DEX,디파이,스테이킹,미국' }
]

export default tags