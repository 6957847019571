import React from 'react'
import PropTypes from 'prop-types'

import ReactGA from 'react-ga'
import styled from 'styled-components'

import markets from '../markets'
import _ from 'lodash'
import qs from 'query-string'

import moment from 'moment'
import 'moment/locale/ko'

const RBITree = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 0;
    
  @keyframes signal {
    0% {
      width: 120px; height: 120px;
      opacity: 0.3;
      border-width: 80px;
      box-shadow: 0 0 30px rgba(255,255,255,0);
    }
    20% {
      width: 160px; height: 160px;
      opacity: 1;
      border-width: 0px;
      box-shadow: 0 0 30px rgba(255,255,255,0.2);
    }
    80% {
      width: 160px; height: 160px;
      opacity: 1;
      border-width: 0px;
      box-shadow: 0 0 30px rgba(255,255,255,0.2);
    }
    100% {
      width: 120px; height: 120px;
      opacity: 0.3;
      border-width: 80px;
      box-shadow: 0 0 30px rgba(255,255,255,0);
    }
  }

  & > div.light {
    position: relative; box-sizing: border-box; padding: 1.5rem;
    height: 240px; display: flex; align-items: center; justify-content: center;
    & > div.signal {
      position: relative; box-sizing: border-box;
      border-radius: 50%;
      animation-name: signal;
      animation-duration: 3.5s;
      animation-iteration-count: infinite;

      background: white;
      border-style: solid;
      border-color: transparent;

      &.red {
        background: rgba(255,51,51,1);
      }
      &.green {
        background: rgba(68,240,126,1);
      }
    }
  }
  & > section {
    position: relative; box-sizing: border-box; padding: 1rem;
    
    & > div {
      display: flex; align-items: center;
      margin: 0.15rem 0;
      & > div { flex: 1 1 100%; padding: 0.35rem; }
      
      & a.coin {
        position: relative; box-sizing: border-box;
        margin: 0.35rem; transition: all 0.3s; background: white;
        border-radius: 50%;  display: flex; align-items: center; justify-content: center;
        width: 25px; height: 25px; border-radius: 50%;
        transform: rotate(-180deg); opacity: 0.2;
        &.live { transform: rotate(-7deg); opacity: 1; }
        &:hover { transform: rotate(0deg); opacity: 1; }
        & > img {
          width: 15px; height: 15px; border-radius: 50%; opacity: 0.75;
          &:hover { opacity: 1 }
        }
        &.win { box-shadow: 0 0 2px red; }
        &.lose { box-shadow: 0 0 2px blue;  }
      }

      & > div.up {
        display: flex; justify-content: flex-end;
        max-width: 50%; flex-wrap: wrap;
        padding: 0.15rem 1.5rem;
      }
      & > div.score {
        max-width: 40px; min-width: 40px; display: flex; justify-content: center;
        font-weight: 700; color: #636465; font-size: 0.8em;
        padding: 0.25rem;
        background: #0b0c11; border-radius: 0.5rem; 
        &.active { color: #0b0c11; background: white; opacity: 1; }
      }
      & > div.down {      
        display: flex; justify-content: flex-start;
        max-width: 50%; flex-wrap: wrap;
        padding: 0.15rem 1.5rem;
      }

      & > div.upCount {
        display: flex; justify-content: flex-end;
        max-width: 50%; flex-wrap: wrap; color: #0b0c11;
        padding: 0.5rem 1.5rem; transition: all 0.3s; opacity: 1;
        font-weight: 900; font-size: 4em; line-height: 1em;
        &.active { color: rgba(68,240,126,1); opacity: 1; }
      }
      & > div.vs {
        max-width: 40px; min-width: 40px; display: flex; justify-content: center;
        font-weight: 700; color: #636465; font-size: 2em;
        padding: 0.5rem;
      }
      & > div.downCount {      
        display: flex; justify-content: flex-start;
        max-width: 50%; flex-wrap: wrap; color: #0b0c11;
        padding: 0.5rem 1.5rem; transition: all 0.3s; opacity: 0.7;
        font-weight: 900; font-size: 4em; line-height: 1em;
        &.active { color: rgba(255,51,51,1); opacity: 1; }
      }

    }
  }
`

// 골드크로스, 데드크로스 코인별 구성으로 체크해주기
class CrossWidgets extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      m60: [],
      m240: [],
      d1: [],
      w1: [],
      dashboard: {},
    }

    this.timer = false
    this.units = ['m60',`m240`,`d1`,`w1`].reverse()
    this.initialize = this.initialize.bind(this)
    this.loadCross = this.loadCross.bind(this)
    this.run = this.run.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {
    const that = this

    const w1 = await that.loadCross(`w1`).catch(e => that.state.w1 || [])
    const d1 = await that.loadCross(`d1`).catch(e => that.state.d1 || [])
    const m240 = await that.loadCross(`m240`).catch(e => that.state.m240 || [])
    const m60 = await that.loadCross(`m60`).catch(e => that.state.m60 || [])
    const dashboard = await this.loadDashboard().catch(e => that.state.dashboard || {})

    this.setState({ w1, d1, m240, m60, dashboard })

    this.timer = window.setInterval(that.run, 5000 * 10)
  }

  async run() {
    const that = this

    const w1 = await that.loadCross(`w1`).catch(e => that.state.w1 || [])
    const d1 = await that.loadCross(`d1`).catch(e => that.state.d1 || [])
    const m240 = await that.loadCross(`m240`).catch(e => that.state.m240 || [])
    const m60 = await that.loadCross(`m60`).catch(e => that.state.m60 || [])
    const dashboard = await this.loadDashboard().catch(e => that.state.dashboard || {})

    ReactGA.event({ category: 'RBI_Tree', action: `Coin RBI Tree`, label: `RBI_TREE` })
    this.setState({ w1, d1, m240, m60, dashboard })
  }

  async loadCross(timing = `d1`) {
    const that = this
    return await fetch(`https://api.coinrbi.com/reports/realtime_cross.${timing}.json`)
      .then((response) => response.json().catch(e => that.state[timing] || []))
      .catch(e => {
        console.log(e.message)
        return that.state[timing] || []
      })
  }

  // 분봉 타점맵에 대한 타임라인 데이터 불러오기
  async loadDashboard() {
    const curAt = new Date(), curMt = moment(curAt).add(-1, 'minutes')
    const at = curMt.format('YYYYMMDDHHmm')
    return await fetch(`https://api.coinrbi.com/datas/${at}`)
      .then((response) => response.json().catch(e => {}))
      .catch(e => ({}))
  }

  render() {
    const that = this

    const curAt = new Date(), curMt = moment(curAt)
    const hour = curMt.format('HH') * 1
    const peaks = [4,5,6,7,8,19,20,21,22,23]

    const items = markets.map(({ market, korean_name, english_name }) => {
      const m60 = that.state['m60'].find(o => o.market === market)
      const m240 = that.state['m240'].find(o => o.market === market)
      const d1= that.state['d1'].find(o => o.market === market)
      const w1 = that.state['w1'].find(o => o.market === market)

      const datas = _.get(that, 'state.dashboard.meterials') || []
      const part = datas.find(data => data.market === market) || {}
      
      const nextO = { market, korean_name, english_name, rbi: { score: 0, r_score: 0} }
      nextO.totalTradeAmount = part.totalTradeAmount || 0
      nextO.direction = part.score > part.r_score ? `win` : (part.score < part.r_score ? `lose` : `draw`)

      const rate = peaks.includes(hour)
        ? { m60: 1.5, m240: 1.85, d1: 2.5,  w1: 2.85 }
        : { m60: 1.35, m240: 1.5, d1: 1.85,  w1: 2.5 }

      nextO.rbi.score = ((_.get(w1, 'rbi.score') || 0) * rate.w1 + (_.get(d1, 'rbi.score') || 0) * rate.d1 + (_.get(m240, 'rbi.score') || 0) * rate.m240 + (_.get(m60, 'rbi.score') * rate.m60 || 0))/4
      nextO.rbi.r_score = ((_.get(w1, 'rbi.r_score') || 0) * rate.w1 + (_.get(d1, 'rbi.r_score') || 0) * rate.d1 + (_.get(m240, 'rbi.r_score') || 0) * rate.m240 + (_.get(m60, 'rbi.r_score') * rate.m60 || 0))/4

      return nextO
    })

    let scores = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.9, 1, 1.25, 1.35, 1.5, 2, 3].reverse()

    const scoreVolumes = [...items.map(item => item.rbi.score), ...items.map(item => item.rbi.r_score)]
    const maxVolume = Math.max(...scoreVolumes), minVolume = Math.min(...scoreVolumes)

    const upCount = items.filter(item => item.rbi.score > item.rbi.r_score).length
    const downCount = items.filter(item => item.rbi.score <= item.rbi.r_score).length

    const search = window.location.search ? qs.parse(window.location.search) : {}

    return (
      <RBITree>
        <div className="light">
          <div className={upCount > downCount ? `signal green` : (upCount < downCount ? `signal red` : `signal`)}></div>
        </div>
        <section>
          <div className="super">
            <div className={downCount <= upCount ? 'upCount active' : 'upCount'}>
              <small style={{ marginRight: '0.55rem', fontWeight: '100' }}>{downCount <= upCount ? '🟢' : ''}</small> 
              {upCount}
            </div>
            <div className="vs">VS</div>
            <div className={downCount >= upCount ? 'downCount active' : 'downCount'}>
              {downCount}
              <small style={{ marginLeft: '0.55rem', fontWeight: '100' }}>{downCount >= upCount ? '🔴' : ''}</small>
            </div>
          </div>
          <div className="header">
            <div className="up"><small style={{ color: '#929394', marginRight: '0.5rem' }}>높은순 강매수세, 낮은순 하방전환</small> <strong>상방</strong></div>
            <div className="score"><strong>종합</strong></div>
            <div className="down"><strong>하방</strong> <small style={{ color: '#929394', marginLeft: '0.5rem' }}>높은순 강매도세, 낮은순 상방전환</small></div>
          </div>
          {scores.map((score, scoreIdx) => {
            const prevScore = scoreIdx > 0 ? scores[scoreIdx-1] : 0
            const score_items = items.filter(item => item.rbi.score > item.rbi.r_score && (item.rbi.score < prevScore && item.rbi.score >= score))
            const r_score_items = items.filter(item => item.rbi.score <= item.rbi.r_score && (item.rbi.r_score < prevScore && item.rbi.r_score >= score))
            if (!score_items.length && !r_score_items.length) { return null }
            return (
              <div className="item" key={scoreIdx}>
                <div className="up">
                  {score_items.map((score_item, idx) => {
                    const name = score_item.market.replace('KRW-', '')
                    return (
                      <a
                        key={`score_item_${idx}`}
                        className={score_item.direction === 'win' ? `coin live ${score_item.direction}` : "coin"}
                        href={`/UPBIT:${name}KRW`}
                        onClick={e => [window.localStorage.setItem('mode', 'trade'), ReactGA.event({ category: 'View', action: `RBI Review`, label: `${name} Cross RBI-UP` })]}
                      >
                        <img
                          src={`https://api.coinrbi.com/symbols/${name}.png`}
                          alt={`${score_item.korean_name}, ${name}, ${score_item.rbi.score}점`}
                          title={`${score_item.korean_name}, ${name}, ${score_item.rbi.score}점`}
                        />
                      </a>
                    )
                  })}
                </div>
                <div className={score >= 0.7 ? "score active" : "score"}>{score}</div>
                <div className="down">
                  {r_score_items.map((score_item, idx) => {
                      const name = score_item.market.replace('KRW-', '')
                      return (
                        <a
                          key={`score_item_${idx}`}
                          className={score_item.direction === 'win' ? `coin live ${score_item.direction}` : "coin"}
                          href={`/UPBIT:${name}KRW`}
                          onClick={e => [window.localStorage.setItem('mode', 'trade'), ReactGA.event({ category: 'View', action: `RBI Review`, label: `${name} Cross RBI-DOWN` })]}
                        >
                          <img
                            src={`https://api.coinrbi.com/symbols/${name}.png`}
                            alt={`${score_item.korean_name}, ${name}, ${score_item.rbi.r_score}점`}
                            title={`${score_item.korean_name}, ${name}, ${score_item.rbi.r_score}점`}
                          />
                        </a>
                      )
                    })}
                </div>
              </div>
            )
          })}
        </section>
      </RBITree>
    )
  }

  componentWillUnmount() {
    if (this.timer) { window.clearInterval(this.timer) }
  }
}

CrossWidgets.propTypes = {
  handleCoin: PropTypes.func
}

CrossWidgets.defaultProps = {
  handleCoin: () => {}
}

export default CrossWidgets