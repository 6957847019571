import React from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import qs from 'query-string'

const Meta = styled.div`
  box-sizing: border-box; margin-bottom: 1rem;
`

Meta.Box = styled.div`
  display: flex; align-items: center; justify-content: space-between;
  box-sizing: border-box; font-size: 0.9em;
  & small { color: #666; }
  & > div:first-child {
    padding: 0.15rem;
    box-sizing: border-box;
    min-width: 100px;
    flex: 1 1 auto;
  }
`

const Progress = styled.div`
  position: relative; box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden; border-radius: 3rem;
  display: flex; margin: 0.5rem 0;
  background: white;
`

Progress.Bar = styled.div`
  position: relative; box-sizing: border-box;
  height: 16px;
  &.blue { background: blue }
  &.red { background: red }
  &.white { background: white }
`

const Block = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0 0.5rem; 
  @media screen and (max-width: 680px) { margin-bottom: 0.5rem; }
  & > header {
    padding: 0.5rem; font-size: 1.2em;
    @media screen and (max-width: 680px) { font-size: 1em; padding: 1rem 0.5rem; margin-bottom: 0; }
  }
  & > div.item {
    position: relative; box-sizing: border-box;
    @media screen and (max-width: 680px) { height: max-content; }
    & > section {
      position: relative; box-sizing: border-box;
      height: 100%; padding: 1rem; background: #0b0c11; border-radius: 0.35rem;
      @media screen and (max-width: 680px) { height: auto; }
      & > section {
        position: relative; box-sizing: border-box;
        & > div.meta {
          position: relative; box-sizing: border-box;
          padding: 0.5rem 0;
        }
        & > div.coins {
          position: relative; box-sizing: border-box;
          display: flex; flex-wrap: wrap;
          transition: all 0.3s;
          & > a {
            position: relative; box-sizing: border-box; transition: all 0.3s;
            margin: 0.15rem; display: flex; align-items: center; justify-content: center;
            width: 35px; height: 35px; 
            background: white; border-radius: 50%; transform: rotate(-15deg);
            & > img { width: 25px; height: 25px; border-radius: 50%;  }
          }
        }
      }
      & > section.message {
        color: #929394; padding: 0.5rem;
      }
    }
  }
`

Block.Group = styled.div`
  display: flex; justify-content: flex-start; 
  @media screen and (max-width: 680px) { overflow: auto; }
  & > div {
    flex: 1 1 100%; min-width: 25%; max-width: 25%;
    @media screen and (max-width: 680px) { flex: none; min-width: 76%; max-width: 76%; }
  }
  &:hover {
    div.coins > a { opacity: 0.2 }
    div.coins > a:hover { opacity: 1; transform: rotate(0deg); }
    div.coins > a.active { opacity: 1; transform: rotate(0deg); }
  }
`

// 골드크로스, 데드크로스 코인별 구성으로 체크해주기
class CrossWidgets extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      m60: [],
      m240: [],
      d1: [],
      w1: [],
      item: {},
    }

    this.timer = false
    this.units = [
      { code: 'm60', text: '1시간' },
      { code: 'm240', text: '4시간' },
      { code: 'd1', text: '일봉' },
      { code: 'w1', text: '주봉' },
    ]
    this.initialize = this.initialize.bind(this)
    this.loadCross = this.loadCross.bind(this)
    this.run = this.run.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {
    const that = this
    that.run()
    this.timer = window.setInterval(that.run, 5000 * 10)
  }

  async run() {
    const that = this

    const m1 = await this.loadCross('m1').catch(e => [])
    const m60 = await this.loadCross('m60').catch(e => [])
    const m240 = await this.loadCross('m240').catch(e => [])
    const d1 = await this.loadCross('d1').catch(e => [])
    const w1 = await this.loadCross('w1').catch(e => [])

    ReactGA.event({ category: 'AutoCross', action: `Coin AutoCross`, label: `AutoCross` })
    this.setState({ m1, m60, m240, d1, w1 })
  }

  async loadCross(timing = 1) {
    return await fetch(`https://api.coinrbi.com/reports/realtime_cross.${timing}.json`)
      .then((response) => response.json().catch(e => []))
      .catch(e => {
        console.log(e.message)
        return []
      })
  }

  render() {
    const that = this

    const filterCoins = this.props.coins || []
    const search = window.location.search ? qs.parse(window.location.search) : {}
    const item = that.state.item

    return (
      <div>
        <Block.Group>
          {that.units.map((unit, unitIdx) => {
            let items = (that.state[unit.code] || [])
            
            if (filterCoins.length) {
              items = items.filter(item => {
                const name = item.market.replace('KRW-', '')
                return filterCoins.includes(name)
              })
            }

            const upItems = items.filter(item => item.rbi.macd || item.rbi.fired)
            const downItems = items.filter(item => item.rbi.r_macd || item.rbi.waterfall)


            // 전봉대비 양수인 경우 찾기
            const winCoins = items.filter(item => {
              const curCoinMin = that.state.m1.find(coin => coin.market === item.market)
              if (!curCoinMin) { return false }
              return curCoinMin.prices[0] && item.prices[1] && curCoinMin.prices[0] > item.prices[1]
            })
            const drawCoins = items.filter(item => {
              const curCoinMin = that.state.m1.find(coin => coin.market === item.market)
              if (!curCoinMin) { return true }
              return curCoinMin.prices[0] && item.prices[1] && curCoinMin.prices[0] === item.prices[1]
            })
            const loseCoins = items.filter(item => {
              const curCoinMin = that.state.m1.find(coin => coin.market === item.market)
              if (!curCoinMin) { return false }
              return curCoinMin.prices[0] && item.prices[1] && curCoinMin.prices[0] < item.prices[1]
            })

            // 전종목 비율 체크해보기
            const sumPercent = Math.round(items
              .filter(item => {
                const curCoinMin = that.state.m1.find(coin => coin.market === item.market)
                if (!curCoinMin) { return false }
                return curCoinMin.prices[0] && item.prices[1] && curCoinMin.prices[0] !== item.prices[1]
              })
              .map(item => {
                const curCoinMin = that.state.m1.find(coin => coin.market === item.market)
                if (!curCoinMin) { return 0 }
                return Math.round(((curCoinMin.prices[0] - item.prices[1])/item.prices[1])*100)/100
              })
              .reduce((s, o) => s + o, 0)*100)/100

            return (
              <Block key={`dead_${unitIdx}`}>
                <header><strong className="underline">{unit.text}</strong> 양음봉 비율</header>
                <Meta>
                  <Progress>
                    <Progress.Bar className="red" style={{ width: `${winCoins.length ? (winCoins.length/items.length)*100 : 0}%` }} />
                    <Progress.Bar className="blue" style={{ width: `${loseCoins.length ? (loseCoins.length/items.length)*100 : 0}%` }} />
                    <Progress.Bar className="white" style={{ width: `${drawCoins.length ? (drawCoins.length/items.length)*100 : 0}%` }} />
                  </Progress>

                  <Meta.Box>
                    <div><strong>양수%</strong> <small>전봉대비</small></div>
                    <div><strong>{winCoins.length}</strong> <small>종</small></div>
                  </Meta.Box>
                  <Meta.Box>
                    <div><strong>0%</strong> <small>전봉대비</small></div>
                    <div><strong>{drawCoins.length}</strong> <small>종</small></div>
                  </Meta.Box>
                  <Meta.Box>
                    <div><strong>음수%</strong> <small>전봉대비</small></div>
                    <div><strong>{loseCoins.length}</strong> <small>종</small></div>
                  </Meta.Box>
                  <Meta.Box>
                    <div><strong>총합</strong> <small>전종목</small></div>
                    <div><strong style={{ color: sumPercent > 0 ? 'red' : (sumPercent < 0 ? 'blue' : 'white') }}>{sumPercent}%</strong></div>
                  </Meta.Box>
                </Meta>
              </Block>
            )
          })}
        </Block.Group>
      </div>
    )
  }

  componentWillUnmount() {
    if (this.timer) { window.clearInterval(this.timer) }
  }
}

export default CrossWidgets