import ReactGA from 'react-ga'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components';
import 'remixicon/fonts/remixicon.css'

import './App.css'

import HomeHeader from './components/HomeHeader'
// import Me from './components/Me'
import Home from './components/Home'
import Players from './components/Players'
import Chart from './components/Chart'
import TwittersWidget from './widgets/TwittersWidget'

ReactGA.initialize('UA-126127835-7')

const GlobalStyle = createGlobalStyle``

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route
              path="/players"
              render={(props) => {
              return (
                <>
                  <HomeHeader />
                  <Players {...props} />
                </>
              )
            }}
          />
          <Route path="/chart" component={Chart} />
          <Route
            path="/twitters"
            render={(props) => {
              return (
                <>
                  <HomeHeader />
                  <TwittersWidget {...props} />
                </>
              )
            }}
          />
          <Route path="/:market?" component={Home} />
        </Switch>
      </Router>
    </>
  )
}

export default App
