import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import qs from 'query-string'

import moment from 'moment'
import 'moment/locale/ko'

import play from 'audio-play'
import load from 'audio-loader'

const comma = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); }

const Wrapper = styled.div`
  position: relative; box-sizing: border-box;
`

const Telegram = styled.div`
  position: absolute; top: 110px; right: -420px; box-sizing: border-box;
  z-index: 3;
  @media screen and (max-width: 2000px) { display: none; }
`

const Panels = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; z-index: 1;
  @media screen and (max-width: 680px) { flex-wrap: wrap; }
`

Panels.Item = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; padding: 1rem;
  & > div.label {
    color: #a1a2a3;
    & > small { color: #626364; }
  }
  & > div.sum { font-size: 1.05em; }
  @media screen and (max-width: 680px) { flex: 1 1 50%; min-width: 50%; max-width: 50%; }
`

const Bar = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; flex-wrap: wrap; background: #0b0c11;
  border-radius: 0.5rem;
  padding: 1rem; margin: 1rem 0;
`

const Items = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; flex-wrap: wrap; background: #0b0c11;
  border-radius: 0.5rem; padding: 1rem;
`

Items.Item = styled.div`
  position: relative; box-sizing: border-box; padding: 0.35rem 0.1rem; 
  min-width: 7.6%; max-width: 7.6%; font-size: 0.82em; line-height: 1.05rem;
  @media screen and (max-width: 580px) { flex: 1 1 50%; min-width: 50%; max-width: 50%; }
  @media screen and (max-width: 780px) { flex: 1 1 50%; min-width: 33%; max-width: 33%; }
  
  & > a {
    display: block; position: relative; opacity: 0.2; border: 1px solid transparent;
    padding: 0.25rem 0.5rem; border-radius: 0.35rem; transition-delay: 0.3s; transition: all 0.3s;
  }
  & > a:hover { background: #010101; opacity: 0.5; }
  & > a.on { opacity: 1; border: 1px solid white;  }
  & > a {
    & > div.msg {
      position: absolute; top: -10px; right: 0px; background: white;
      font-size: 11px; line-height: 12px;
      border-radius: 0.25rem; color: #141722; padding: 0.2rem 0.3rem;
    }
  }
  & > a.active-up {
    display: flex; color: yellow; justify-content: space-between;
    border: 2px solid yellow;
    & > div.msg { background: yellow; color: #0b0c11; }
  }
  & > a.active-down {
    display: flex; color: red; justify-content: space-between;
    border: 2px solid red;
    & > div.msg { background: red; color: white; }
  }
`

const Menus = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; font-size: 1.8em;
  display: flex; justify-content: space-between;
  & > div.msg { font-size: 14px }
`

class DashboardWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = { at: new Date(), current: {}, markets: [], data: {}, prevData: {} }

    this.timer = false
    this.initialize = this.initialize.bind(this)
    this.run = this.run.bind(this)
    this.loadMarkets = this.loadMarkets.bind(this)
    this.loadData = this.loadData.bind(this)
    this.setDashboardSound = this.setDashboardSound.bind(this)
  }
  
  componentDidMount() {
    // 초창기 접속했을 때 소리 없게 만들기
    if (!window.localStorage.getItem('muted')) { window.localStorage.setItem('muted', 'on') }
    this.initialize()
  }

  componentWillUnmount() {
    if (this.timer) { window.clearInterval(this.timer) }
  }
  
  componentDidUpdate() {
    if (this.timer) { window.clearInterval(this.timer) }
  }

  async initialize() {
    const that = this
    // 모든 코인 리스트 배열 /markets.json
    await that.loadMarkets()
    if (that.timer) { window.clearInterval(that.timer) }
    if (!that.timer) { window.setInterval(that.run, 1000 * 10) }
  }

  async loadMarkets() {
    const that = this
    return await fetch(`/markets.json`)
      .then((response) => response.json().catch(e => []))
      .then(async (markets) => await new Promise((r) => that.setState({ markets }, r)))
      .catch(e => {})
  }

  async loadData(mtFormat) {
    return await fetch(`https://api.coinrbi.com/datas/${mtFormat}`)
      .then((res) => res.json().catch(e => null))
      .catch(e => null)
  }

  // 상황판에 표현할 데이터를 가져오는 장치
  async run() {
    const that = this
    
    const at = new Date(), mt = moment(at), pt = moment(at).add(-1, 'minutes')
    const mtFormat = mt.format('YYYYMMDDHHmm')
    const ptFormat = pt.format('YYYYMMDDHHmm')
    
    let resData = await that.loadData(mtFormat)
    if (!resData) { resData = await that.loadData(ptFormat) }

    return await Promise
      .resolve(resData)
      .then(async (data) => {
        if (!data) { return }
        const next = { prevData: JSON.parse(JSON.stringify(that.state.data)), data }
        ReactGA.event({ category: 'Response', action: `Live MACD Download`, label: `MACD Download` })

        // 현재 수신 받았던 기록과 다르다면 알림한다.
        const totalPrevTradeAmount = (that.state.data.meterials || []).reduce((s, material) => s + material.totalTradeAmount * 1, 0)
        const totalTradeAmount= (data.meterials || []).reduce((s, material) => s + material.totalTradeAmount * 1, 0)
        const isChanged = totalPrevTradeAmount !== totalTradeAmount

        let coins = _.sortBy((data.meterials || []).map((meterial) => {
          return { name: meterial.market.replace('KRW-', ''), ...meterial }
        }), 'totalTradeAmount').reverse()
        
        const filterCoins = this.props.coins || []
        if (filterCoins.length) { coins = coins.filter(item => filterCoins.includes(item.name)) }
        if (!isChanged && data.meterials.length > 0) { return }
        const topCoin = coins[0]
        next.at = at
        let icon = `🔥`

        const muted = window.localStorage.getItem('muted')
        if (muted === 'off') {
          if (totalTradeAmount >= 12000000000) {
            icon = `🌋`
            load('./gangnam.mp3').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
          } else if (totalTradeAmount >= 10000000000) {
            icon = `💣`
            load('./digitalMediaSecurity.mp3').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
          } else if (totalTradeAmount >= 9000000000) {
            icon = `🌕`
            load('./busan.mp3').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
          } else if (totalTradeAmount >= 8000000000) {
            icon = `🚀`
            if (topCoin && topCoin.prices && topCoin.prices[0] < topCoin.prices[1] ) {
              load('./train-down.mp3').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
            } else {
              load('./train-up.mp3').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
            }
          } else if (totalTradeAmount >= 5000000000) {
            icon = `🚨`
            load('./up.mp3').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
          } else if (totalTradeAmount >= 500000000) {
            icon = `🔥`
            load('./bell.wav').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
          } else {
            icon = `⚪`
            load('./beep.wav').then(((buff) => play(buff, { volume: 0.5, autoplay: true })))
          }
        }
        return await new Promise(r => that.setState(next, r))
      })
      .catch(e => { return })
  }

  async setDashboardSound(muted) {
    const that = this
    if (muted) { window.localStorage.setItem('muted', 'on') }
    if (!muted) { window.localStorage.setItem('muted', 'off') }
    await new Promise((r) => that.setState({ loading: false }, r))
  }

  render() {
    const that = this
    const { current, markets, data, prevData } = that.state

    const muted = (window.localStorage.getItem('muted') && window.localStorage.getItem('muted') === 'on')
      ? true
      : false

    let coins = _.sortBy(markets, ['market'])
    const filterCoins = this.props.coins || []
    if (filterCoins.length) {
      coins = coins.filter(item => {
        const name = item.market.replace('KRW-', '')
        return filterCoins.includes(name)
      })
    }

    const curAt = data.createdAt || new Date(), curMt = moment(curAt)

    const prevMaterials = _.get(prevData, 'meterials') || []
    const meterials = data ? (data.meterials || prevMaterials || []) : []

    const totalTradeAmount= (meterials || []).reduce((s, material) => s + material.totalTradeAmount * 1, 0)

    const averageScrore = meterials.length
      ? Math.floor((meterials || []).reduce((s, material) => s + material.score * 1, 0)*100/meterials.length)/100
      : 0
    const averagerScrore = meterials.length
      ? Math.floor((meterials || []).reduce((s, material) => s + material.r_score * 1, 0)*100/meterials.length)/100
      : 0

    return (
      <Wrapper>
        {/* {isWide && (data.telegramReport || prevData.telegramReport) ? (
          <Telegram style={{ width: '400px', height: '1000px' }}>
            <iframe src={`/live_telegram.html?id=${data.telegramReport || prevData.telegramReport}`} height={1000} width={480} frameBorder={0} />
          </Telegram>
        ) : null} */}

        <Panels>
          <Panels.Item>
            <div className="label" style={{ paddingBottom: '0.35rem' }}>타점시각</div>
            <div className="sum">
               <strong style={{ marginRight: '0.35rem' }}>{curMt.format('HH시 mm분')}</strong>
            </div>
          </Panels.Item>
          <Panels.Item>
            <div className="label" style={{ paddingBottom: '0.35rem' }}>판분위기 <small>상황</small></div>
            <div className="sum">
              {averageScrore > averagerScrore ? `🚅 우세` : (averageScrore < averagerScrore ? `🏃 우세` : `🤔`)}
            </div>
          </Panels.Item>
          <Panels.Item>
            <div className="label" style={{ paddingBottom: '0.35rem' }}>골드크로스 <small>평균</small></div>
            <div className="sum">
              <strong style={{ marginRight: '0.35rem' }}>{averageScrore}</strong>
              <small>점</small>
            </div>
          </Panels.Item>
          <Panels.Item>
            <div className="label" style={{ paddingBottom: '0.35rem' }}>데드크로스 <small>평균</small></div>
            <div className="sum">
              <strong style={{ marginRight: '0.35rem' }}>{averagerScrore}</strong>
              <small>점</small>
            </div>
          </Panels.Item>
          <Panels.Item>
            <div className="label" style={{ paddingBottom: '0.35rem' }}>타점종수</div>
            <div className="sum">
              <strong style={{ marginRight: '0.35rem' }}>{comma(meterials.length)}</strong>
              <small>종</small>
            </div>
          </Panels.Item>
          <Panels.Item>
            <div className="label" style={{ paddingBottom: '0.35rem' }}>총 실거래액 <small>3분 합계</small></div>
            <div className="sum">
              <strong style={{ marginRight: '0.35rem' }}>{comma(totalTradeAmount)}</strong>
              <small>원</small>
            </div>
          </Panels.Item>
        </Panels>
        
        <Items>
          {coins.map((coin, cIdx) => {
            const name = coin.market.replace(/KRW-/ig, '')
            const material = meterials.find(meterial => meterial.market === coin.market)
            
            const totalTradeAmount = _.get(material, 'totalTradeAmount') || 0
            const score = _.get(material, 'score') || 0
            const r_score = _.get(material, 'r_score') || 0

            const prices = _.get(material, 'prices') || []
            const bigWhale = (totalTradeAmount >= 700000000)
              ? (score > r_score ? `active-up` : `active-down`)
              : ''

            // 거래액 사이즈 마크
            let icon = totalTradeAmount > 100000000 ? '🔴' : '⚪'
            icon = totalTradeAmount >= 500000000 ? '🚨' : icon
            icon = totalTradeAmount >= 3000000000 ? '🚀' : icon
            icon = totalTradeAmount >= 5000000000 ? '🌕' : icon
            icon = totalTradeAmount >= 10000000000 ? '💣' : icon
            icon = totalTradeAmount >= 20000000000 ? '🌋' : icon

            // 타점방향 마크
            let directionIcon = '🎯'
            directionIcon = score > r_score ? '🚅' : directionIcon
            directionIcon = score < r_score ? '🏃' : directionIcon
            directionIcon = score === r_score ? '🤔' : directionIcon

            let vScore = score > r_score ? score : r_score

            // 거래액 표현부
            let trade_amount = Math.floor(totalTradeAmount*100/100000000)/100
            
            return (
              <Items.Item key={cIdx}>
                <a
                  className={`${bigWhale || ''} ${score > r_score ? `on` : ''}`}
                  href={`/UPBIT:${name}KRW`}
                  onMouseEnter={e => material ? [ReactGA.event({ category: 'Preview', action: `Coin Preview`, label: `${coin.market} Coin Preview` }), that.setState({ current: { coin, material } })] : null}
                  onClick={e => [window.localStorage.setItem('mode', 'trade'), ReactGA.event({ category: 'View', action: `Chart Review`, label: `${coin.market} Chart` })]}
                >
                  {trade_amount >= 1 ? <div className="msg"><strong>{trade_amount}</strong>억 <strong>{vScore}</strong>점</div> : null}
                  {trade_amount < 1 && trade_amount >= 0.3 ? <div className="msg"><strong>{trade_amount}</strong>억 <strong>{vScore}</strong>점</div> : null}
                  <div>
                    <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100%' }}>
                      <small style={{ color: '#e5e6e7' }}>{coin.korean_name}</small>
                    </div>
                    <div>
                      <strong style={{ marginRight: '0.25rem' }}>{name}</strong>
                      {material ? (
                        <small>
                          {totalTradeAmount > 30000000}<span>{icon}</span>
                          {score >= 0.45 || r_score >= 0.45}<span>{directionIcon}</span>
                        </small>
                      ) : null}
                    </div>
                  </div>
                  
                </a>
              </Items.Item>
            )
          })}
        </Items>
        <Menus>
          <div className="msg">
            {data && data.activeUserCount
              ?(<span>🧑‍🤝‍🧑 이시각 <strong>{comma(data.activeUserCount)}명</strong>이 함께 코알중... 👀</span>)
              : <span>🦄 <strong>잠시만요!</strong> 데이터를 불러오고 있어요! 우측에 소리를 켜면 차트를 사플로 느낄 수 있습니다.</span>}
          </div>
          <div>
            <span>
              {muted
                ? <a href="#dashboardSoundOff" onClick={e => [e.stopPropagation(), e.preventDefault(), that.setDashboardSound(false)]}><i className="ri-volume-mute-line"></i></a>
                : <a href="#dashboardSoundOn" onClick={e => [e.stopPropagation(), e.preventDefault(), that.setDashboardSound(true)]}><i className="ri-volume-up-line"></i></a>}
            </span>
          </div>
        </Menus>
      </Wrapper>
    )
  }
}

export default DashboardWidget
