import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import markets from '../markets'
import tags from '../tags'
import _ from 'lodash'
import qs from 'query-string'

import moment from 'moment'
import 'moment/locale/ko'

import 'animate.css'

// 업비트 연동구
import crypto from 'crypto'
import * as uuid from 'uuid'
import jsonwebtoken from 'jsonwebtoken'

const getMinCandles = async (market = 'KRW-BTC', unit = 1, candleCount = 3) => {
  const url = `https://api.upbit.com/v1/candles/minutes/${unit}?market=${market}&count=${candleCount}`
  const options = { method: 'GET', headers: { Accept: 'application/json' }, compress: true }
  const candles = await fetch(`${url}`, options)
    .then(res => res.json().catch(e => null))
    .catch(e => console.log(` * getMinCandles 캔들에러`, e.stack))
  return candles
}

const sign = jsonwebtoken.sign

const headers = (settings = {}, options = {}) => {
  const access_key = window.localStorage.getItem('upbitAccessKey')
  const secret_key = window.localStorage.getItem('upbitSecretKey')
  const payload = { access_key, nonce: uuid.v4(), ...(options.payload || {}) }
  const base = { Authorization: `Bearer ${sign(payload, secret_key)}`, ...settings }
  return { ...base }
}

const queryHash = (query) => crypto.createHash('sha512').update(qs.stringify(query), 'utf-8').digest('hex')

const postOrder = async (market) => {
  if (!market) { return alert(`시장정보가 없습니다.`) }

  const access_key = window.localStorage.getItem('upbitAccessKey')
  const secret_key = window.localStorage.getItem('upbitSecretKey')
  if (!access_key || !secret_key) { return alert(`상단의 자물쇠 모양을 눌러 업비트 연동을 마무리해야 가이드 매수가 가능합니다.`) }

  const candles = await getMinCandles(market)
  if (!candles) { return alert(`${market}의 가격정보를 불러오는 도중 문제가 발생하였습니다.`) }

  const minPrice = Math.min(...candles.map(candle => candle.trade_price))
  if (!minPrice) { return alert(`${market}의 최저가 가격정보를 불러오는 도중 문제가 발생하였습니다.`) }

  const isConfirmed = window.confirm(`${market}의 현재 위치에 가이드 매수를 진행 할까요?`)
  if (!isConfirmed) { return false }

  const won = window.prompt(`${market}의 현재 가이드 단가는 ${comma(minPrice)}원입니다. 구매할 액수를 설정해주세요.`, 5500)
  if (!won) { return }
  if (_.isNaN(won * 1) || !_.isNumber(won * 1)) { return alert(`숫자만 기재가 가능합니다.`) }

  const query = {}
  query.market = market
  query.side = 'bid'
  query.volume = `${won/minPrice}`
  query.price = `${minPrice}`
  query.ord_type = 'limit'

  const payload = { query_hash: queryHash(query), query_hash_alg: 'SHA512' }

  return await fetch(`https://api.upbit.com/v1/orders`, {
    method: 'post',
    headers: headers({ 'Content-Type': 'application/json' }, { payload }),
    body: JSON.stringify(query)
  })
  .then(res => res.json())
  .then(result => {
    alert(`매수가 성공적으로 이루어졌습니다.`)
    return false
  })
  .catch(e => {
    console.log(e.stack)
    alert(`매수오류가 발생하였습니다. ${e.message} ${e.stack}`)
    return false
  })
}

// 업비트 연동구

const comma = (x) => { return (x > 999 || x < -999) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : x }

const Tabs = styled.nav`
  flex: 1 1 100%; text-align: center;
  position: relative; box-sizing: border-box;
  & > a {
    display: inline-block;
    border-radius: 10px; text-align: center; transition: all 0.3s;
    padding: 0.35rem 0.75rem;
    &.active {
      background: white; color: black; opacity: 1;
      strong { color: black; }
    }
  }
`
Tabs.Group = styled.div`
  display: flex; padding: 1rem 0;
`

const Nav = styled.nav`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; justify-content: center;
  padding: 0.25rem;
  & > a {
    max-width:200px; min-width:200px; padding: 0.5rem 1rem;
    border-radius: 10px; text-align: center; transition: all 0.3s;
    margin: 0.4rem; background: #0b0c11; opacity: 0.8;
    small { color: #929292; }
    strong { color: white; }
    &.active {
      background: white; color: black; opacity: 1;
      strong { color: black; }
    }
    &:hover { opacity: 1; }
  }
`

const Control = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem;
  & > input {
    display: block; width: 100%; box-sizing: border-box; outline: none;
    padding: 0.5rem; border-radius: 0.35rem; border: 0;
  }
`

const Item = styled.a`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; transition: all 0.3s;
  background: #0b0c11; border-radius: 0.35rem;
  margin: 0.75rem 0; padding: 0.5rem 0;
  border: 3px solid #0b0c11; line-height: 18px; font-size: 14px;
  &.yellow { border: 3px solid yellow;  }
  &.white { border: 3px solid white; }
  & > div { flex: 1 1 100%; padding: 0.35em; }
  & small { color: #929292; }
  & div.symbol {
    position: relative; box-sizing: border-box;
    margin: 0.25rem; display: flex; align-items: center; justify-content: center;
    width: 30px; height: 30px;
    background: white; border-radius: 50%;
    & > img { width: 20px; height: 20px; border-radius: 50%; }
  }
  & > .pop {
    position: absolute; right: 0; top: -0.75rem;
    border-radius: 0.35rem;
    font-size: 0.8em; color: black; background: white;
    line-height: 1rem; padding: 0.25rem 0.75rem;
  }
  &.yellow > .pop{ background: yellow;  }
  &.white > .pop { background: white; }
  &:hover { background: #17181e; cursor: pointer; }
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
`

// 골드크로스, 데드크로스 코인별 구성으로 체크해주기
class TableWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      m15: [],
      m30: [],
      m60: [],
      m240: [],
      mode: props.pick ?'long' : 'short',
      prev: {},
      prevFt: null,
      loading: false,
      keyword: '',

      dashboard: {},

      exception: props.pick ?'all' : 'dead',
      country: 'all',
      exchange: 'all',
      trade: 'all',
    }

    this.timer = false
    this.units = [
      { code: 'm1', text: '1분봉' },
      { code: 'm3', text: '3분봉' },
      { code: 'm15', text: '15분봉' },
      { code: 'm30', text: '30분봉' },
      { code: 'm60', text: '1시간' },
      { code: 'm240', text: '4시간' },
      { code: 'd1', text: '일봉' },
      { code: 'w1', text: '주봉' },
    ].reverse()

    this.initialize = this.initialize.bind(this)
    this.loadDashboard = this.loadDashboard.bind(this)
    this.loadCross = this.loadCross.bind(this)
    this.getItems = this.getItems.bind(this)
    this.run = this.run.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {
    const that = this
    that.run(true)
    this.timer = window.setInterval(that.run, 5000 * 10)
  }

  // 분봉 타점맵에 대한 타임라인 데이터 불러오기
  async loadDashboard() {
    const curAt = new Date(), curMt = moment(curAt).add(-1, 'minutes')
    const at = curMt.format('YYYYMMDDHHmm')
    return await fetch(`https://api.coinrbi.com/datas/${at}`)
      .then((response) => response.json().catch(e => {}))
      .catch(e => ({}))
  }

  async run(isFirst) {
    const that = this

    const m1 = await this.loadCross('m1').catch(e => [])
    const m3 = await this.loadCross('m3').catch(e => [])
    const m15 = await this.loadCross('m15').catch(e => [])
    const m30 = await this.loadCross('m30').catch(e => [])
    const m60 = await this.loadCross('m60').catch(e => [])
    const m240 = await this.loadCross('m240').catch(e => [])
    const d1 = await this.loadCross('d1').catch(e => [])
    const w1 = await this.loadCross('w1').catch(e => [])
    const dashboard = await this.loadDashboard().catch(e => ({}))

    ReactGA.event({ category: 'AutoCross', action: `Coin AutoCross`, label: `AutoCross` })

    const next = { m1, m3, m15, m30, m60, m240, d1, w1, dashboard, loading: false }

    // 과거 데이터를 집계
    const curAt = new Date(), curMt = moment(curAt)
    const curFt = curMt.format('YYYYMMDDHHmm')
    const prevFt = that.state.prevFt

    if (!isFirst && curFt != prevFt) {
      next.prevFt = curFt
      next.prev = Object.values(that.state.prev).length <= 5
        ? { ...that.state.prev }
        : { ...Object.values(that.state.prev).filter((o, i) => i === (Object.values(that.state.prev).length -1)) }
      next.prev[curFt] = that.getItems().filter(item => !['KRW-BTC', 'KRW-ETH'].includes(item.market))
    }

    this.setState(next)
  }

  async loadCross(timing = 1) {
    return await fetch(`https://api.coinrbi.com/reports/realtime_cross.${timing}.json`)
      .then((response) => response.json().catch(e => []))
      .catch(e => {
        console.log(e.message)
        return []
      })
  }

  getItems() {
    const that = this

    // 시간 상수 구성
    const curAt = new Date(), curMt = moment(curAt)
    const hour = curMt.format('HH') * 1
    const peaks = [4,5,6,7,8,19,20,21,22,23]

    // 아이템을 재정렬해서 순위가 높은 것 기준으로 정배열 해줘야한다.
    const items = markets.map(item => {
      const combined = { ...item }
      
      combined.meta = {}
      combined.meta.w1 = (that.state.w1 || []).find(_item => _item.market === item.market) || {}
      combined.meta.d1 = (that.state.d1 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m240 = (that.state.m240 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m60 = (that.state.m60 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m30 = (that.state.m30 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m15 = (that.state.m15 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m3 = (that.state.m3 || []).find(_item => _item.market === item.market) || {}
      combined.meta.m1 = (that.state.m1 || []).find(_item => _item.market === item.market) || {}
      
      let score_w1 = _.get(combined.meta, 'w1.rbi.macd') ? 3 : (_.get(combined.meta, 'w1.rbi.r_macd') ? -3 : 0)
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.rbi.obv') ? 3 : (_.get(combined.meta, 'w1.rbi.r_obv') ? -3 : 0))
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.fired') ? 2 : (_.get(combined.meta, 'w1.waterfall') ? -2 : 0))
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.rbi.wr') ? 1 : (_.get(combined.meta, 'w1.rbi.wr') ? -1 : 0))
      score_w1 = score_w1 + (_.get(combined.meta, 'w1.rbi.rsi') ? 1 : (_.get(combined.meta, 'w1.rbi.rsi') ? -1 : 0))
      let score_d1 = _.get(combined.meta, 'd1.rbi.macd') ? 2 : (_.get(combined.meta, 'd1.rbi.r_macd') ? -2 : 0)
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.rbi.obv') ? 2 : (_.get(combined.meta, 'd1.rbi.r_obv') ? -2 : 0))
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.fired') ? 3 : (_.get(combined.meta, 'd1.waterfall') ? -3 : 0))
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.rbi.wr') ? 0.5 : (_.get(combined.meta, 'd1.rbi.r_wr') ? -0.5 : 0))
      score_d1 = score_d1 + (_.get(combined.meta, 'd1.rbi.rsi') ? 0.5 : (_.get(combined.meta, 'd1.rbi.r_rsi') ? -0.5 : 0))
      let score_m240 = _.get(combined.meta, 'm240.rbi.macd') ? 1 : (_.get(combined.meta, 'm240.rbi.r_macd') ? -1 : 0)
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.rbi.obv') ? 1 : (_.get(combined.meta, 'm240.rbi.r_obv') ? -1 : 0))
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.fired') ? 2 : (_.get(combined.meta, 'm240.waterfall') ? -2 : 0))
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.rbi.wr') ? 0.25 : (_.get(combined.meta, 'm240.rbi.r_wr') ? -0.25 : 0))
      score_m240 = score_m240 + (_.get(combined.meta, 'm240.rbi.rsi') ? 0.25 : (_.get(combined.meta, 'm240.rbi.r_rsi') ? -0.25 : 0))
      let score_m60 = _.get(combined.meta, 'm60.rbi.macd') ? 0.5 : (_.get(combined.meta, 'm60.rbi.r_macd') ? -0.5 : 0)
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.rbi.obv') ? 0.5 : (_.get(combined.meta, 'm60.rbi.r_obv') ? -0.5 : 0))
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.fired') ? 1 : (_.get(combined.meta, 'm60.waterfall') ? -1 : 0))
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.rbi.wr') ? 0.25 : (_.get(combined.meta, 'm60.rbi.r_wr') ? -0.25 : 0))
      score_m60 = score_m60 + (_.get(combined.meta, 'm60.rbi.rsi') ? 0.25 : (_.get(combined.meta, 'm60.rbi.r_sri') ? -0.25 : 0))
      let score_m30 = _.get(combined.meta, 'm30.rbi.macd') ? 0.25 : (_.get(combined.meta, 'm30.rbi.r_macd') ? -0.25 : 0)
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.rbi.obv') ? 0.25 : (_.get(combined.meta, 'm30.rbi.r_obv') ? -0.25 : 0))
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.fired') ? 0.5 : (_.get(combined.meta, 'm30.waterfall') ? -0.5 : 0))
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.rbi.wr') ? 0.5 : (_.get(combined.meta, 'm30.rbi.r_wr') ? -0.5 : 0))
      score_m30 = score_m30 + (_.get(combined.meta, 'm30.rbi.rsi') ? 0.5 : (_.get(combined.meta, 'm30.rbi.r_rsi') ? -0.5 : 0))
      let score_m15 = _.get(combined.meta, 'm15.rbi.macd') ? 0.125 : (_.get(combined.meta, 'm15.rbi.r_macd') ? -0.125 : 0)
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.rbi.obv') ? 0.125 : (_.get(combined.meta, 'm15.rbi.r_obv') ? -0.125 : 0))
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.fired') ? 0.125 : (_.get(combined.meta, 'm15.waterfall') ? -0.125 : 0))
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.rbi.wr') ? 0.1 : (_.get(combined.meta, 'm15.rbi.r_wr') ? -0.1 : 0))
      score_m15 = score_m15 + (_.get(combined.meta, 'm15.rbi.rsi') ? 0.1 : (_.get(combined.meta, 'm15.rbi.r_rsi') ? -1 : 0))
      let score_m3 = _.get(combined.meta, 'm3.rbi.macd') ? 0.075 : (_.get(combined.meta, 'm3.rbi.r_macd') ? -0.075 : 0)
      score_m3 = score_m3 + (_.get(combined.meta, 'm3.rbi.obv') ? 0.075 : (_.get(combined.meta, 'm3.rbi.r_obv') ? -0.075 : 0))
      score_m3 = score_m3 + (_.get(combined.meta, 'm3.fired') ? 0.125 : (_.get(combined.meta, 'm3.waterfall') ? -0.125 : 0))
      let score_m1 = _.get(combined.meta, 'm1.rbi.macd') ? 0.0035 : (_.get(combined.meta, 'm1.rbi.r_macd') ? -0.0035 : 0)
      score_m1 = score_m1 + (_.get(combined.meta, 'm1.rbi.obv') ? 0.0035 : (_.get(combined.meta, 'm1.rbi.r_obv') ? -0.0035 : 0))
      score_m1 = score_m1 + (_.get(combined.meta, 'm1.fired') ? 0.075 : (_.get(combined.meta, 'm1.waterfall') ? -0.075 : 0))

      combined.score = score_w1 + score_d1 + score_m240 + score_m60 + score_m30 + score_m15
        + (peaks.includes(hour) ? (score_m3 + score_m1) * 49 : (score_m3 + score_m1))
      
      // tags에서 있으면 붙여넣어준다.
      const tag = tags.find(tag => tag.market === item.market) || {}

      return { ...combined, tag: tag }
    })

    items.sort((a, b) => {
      if (that.state.mode === 'long') {
        return a.score < b.score ? 1 : -1
      } else {
        return a.score < b.score ? -1 : 1
      }
    })

    return items
  }

  render() {
    if (this.state.loading) { return null }

    const that = this
    const { pick } = this.props

    const search = window.location.search ? qs.parse(window.location.search) : {}

    let items = that.getItems()
    if (pick && pick.market) { items = items.filter(item => item.market === pick.market) }

    const filtered = items
      .map(item => {
        if (!that.state.keyword) { return item }
        const name = item.market.replace('KRW-', '')
        const keyword = that.state.keyword || ''
        const keys = keyword.split(',')
        return keys.map(k => k.trim()).map((k) => {
          if (!k) { return false }
          if (item.korean_name.indexOf(k) >= 0) { return true }
          if (name.indexOf(k) >= 0) { return true }
          return false
        }).includes(true) ? item : { ...item, hide: true }
      })
      .map(item => {
        let next = item
        const meta = item.meta || {}
        if (that.state.exception !== 'all') {
          // 데드구간이 하나라도 껴있으면 제외
          if (!next.hide && that.state.exception === 'dead'
            && (
              !_.get(meta, 'w1.rbi.r_macd') &&
              !_.get(meta, 'd1.rbi.r_macd') &&
              !_.get(meta, 'm240.rbi.r_macd') &&
              !_.get(meta, 'm60.rbi.r_macd') &&
              !_.get(meta, 'm30.rbi.r_macd') &&
              !_.get(meta, 'm15.rbi.r_macd') &&
              !_.get(meta, 'm3.rbi.r_macd') &&
              !_.get(meta, 'm1.rbi.r_macd')
            )
          ) { next = item }
          else if (!next.hide && that.state.exception === 'golden'
            && (
              !_.get(meta, 'w1.rbi.macd') &&
              !_.get(meta, 'd1.rbi.macd') &&
              !_.get(meta, 'm240.rbi.macd') &&
              !_.get(meta, 'm60.rbi.macd') &&
              !_.get(meta, 'm30.rbi.macd') &&
              !_.get(meta, 'm15.rbi.macd') &&
              !_.get(meta, 'm3.rbi.macd') &&
              !_.get(meta, 'm1.rbi.macd')
            )
          ) { next = item }
          else { next = { ...item, hide: true } }
        }
        return next
      })

    // 코인리스트
    return (
      <>
        {!pick ? (
          <Nav>
            <a
              href="#longMode"
              className={that.state.mode === 'long' ? 'active' : ''}
              onClick={e => {
                return [e.preventDefault(), that.setState({ mode: 'long', prevFt: null, prev: [] })]
              }}
            >
              <div><strong>🔥 상방·추격</strong></div>
              <div><small><strong>매수</strong> 강도 기준</small></div>
            </a>
            <a
              href="#shortMode"
              className={that.state.mode === 'short' ? 'active' : ''}
              onClick={e => {
                return [e.preventDefault(), that.setState({ mode: 'short', prevFt: null, prev: [] })]
              }}
            >
              <div><strong>💧 하방·저가</strong></div>
              <div><small><strong>매도</strong> 강도 기준</small></div>
            </a>
          </Nav>
        ) : null}

        {!pick ? (
          <Tabs.Group>
            <Tabs>
              <a
                href="#all"
                onClick={e => [e.preventDefault(), that.setState({ exception: 'all' })]}
                className={that.state.exception === 'all' ? 'active' : ''}
              >
                전체
              </a>
              <a
                href="#usa"
                onClick={e => [e.preventDefault(), that.setState({ exception: 'dead' })]}
                className={that.state.exception === 'dead' ? 'active' : ''}
              >
                매수관점
              </a>
              <a
                href="#asia"
                onClick={e => [e.preventDefault(), that.setState({ exception: 'golden' })]}
                className={that.state.exception === 'golden' ? 'active' : ''}
              >
                매도관점
              </a>
            </Tabs>
          </Tabs.Group>
        ) : null}

        {!pick ? (
          <Control>
            <input
              type="text"
              defaultValue={that.state.keyword}
              placeholder={`검색할코인을 쉼표로 입력해주세요.`}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  return that.setState({ keyword: e.target.value })
                }
              }}
            />
          </Control>
        ) : null}

        <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: '380px', overflow: 'auto' }}>
          <Item.Group style={{ flex: '1 1 auto', padding: '0 0.35rem' }}>
            {filtered.map((item, coinIdx) => {
              const name = item.market.replace('KRW-', '')
              const meta = item.meta || {}

              // 방향 알고리즘
              const minLowPrice = Math.min(...(_.get(item, 'meta.m240.prices') || [])) || 0
              const minFirstPrice = Math.min(...(_.get(item, 'meta.m30.prices') || [])) || 0
              const currentPrice = _.get(item, 'meta.m1.prices[0]') || 0
              const direction = (currentPrice <= (minLowPrice * 1.03))
                ? 'yellow'
                : ((currentPrice <= (minFirstPrice * 1.015)) ? 'white' : '')

              // onClick={e => [postOrder(item.market)]}
              
              return (
                <Item
                  key={`coin_${coinIdx}`}
                  className={`${direction} animate__animated animate__fadeInDown`}
                  style={{ 'animationDelay': `${coinIdx * 0.01}s`, display: item.hide ? 'none' : null }}
                  href={`/UPBIT:${name}KRW`}
                  onClick={e => [window.localStorage.setItem('mode', 'trade'), ReactGA.event({ category: 'View', action: `Go Upbit Review`, label: `${name} Upbit Table` })]}
                >
                  {((item) => {
                    const datas = _.get(that, 'state.dashboard.meterials') || []
                    const part = datas.find(data => data.market === item.market) || {}
                    if (!part || !part.market) { return null }

                    const totalTradeAmount = part.totalTradeAmount || 0
                    const appealTotal = totalTradeAmount >= 100000000
                      ? `${comma(Math.round(totalTradeAmount*100/100000000)/100)}억원`
                      :`${comma(Math.round(totalTradeAmount*100/10000000)/100)}천만원`
                    const gap = part.prices[0] !== part.prices[1]

                    const firstPrice = comma(part.prices[1] || 0)
                    const lastPrice = comma(part.prices[0] || 0)

                    const direction = part.score > part.r_score
                      ? `🚅` : (part.score < part.r_score ? `🏃` : `🤔`)

                    return (
                      <div className="pop">
                        {gap ? (
                          <>
                            <small style={{ marginRight: '0.35rem' }}>{firstPrice}</small>
                            <small style={{ marginRight: '0.35rem' }}>→</small>
                            <small style={{ marginRight: '0.35rem' }}>{lastPrice} 원</small>
                          </>
                        ) : null}
                        {!gap ? (
                          <>
                            <small style={{ marginRight: '0.35rem' }}>{lastPrice} 원</small>
                          </>
                        ) : null}
                        <strong>{appealTotal} {direction}</strong>
                      </div>
                    )
                  })(item)}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '50px', minWidth: '50px' }}>
                    <div style={{ textAlign: 'center' }}>
                      <strong style={{ fontSize: '1.3em', color: coinIdx < 20 ? 'yellow' : 'inherit' }}>{coinIdx+1}</strong><br />
                      <small>
                        {(() => {
                          const curAt = new Date(), curMt = moment(curAt)
                          const prevFt = curMt.format('YYYYMMDDHHmm')
                          const prevItems = that.state.prev[prevFt] || []
                          const prevItem = prevItems.find(pItem => pItem.market === item.market)
                          if (!prevItem) { return null }
                          const prevIdx = prevItems.findIndex(pItem => pItem.market === item.market)
                          if (prevIdx < 0) { return null }
                          return (coinIdx - prevIdx) > 0 ? `-${coinIdx - prevIdx}` : ((coinIdx - prevIdx) < 0 ? `+${Math.abs(coinIdx - prevIdx)}` :  null)
                        })()}
                      </small>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '50px', minWidth: '50px' }}>
                    <div
                      className="symbol"
                      key={`symbol_${coinIdx}`}
                    >
                      <img
                        src={`https://api.coinrbi.com/symbols/${name}.png`}
                        alt={`${item.korean_name}, ${name}`}
                        title={`${item.korean_name}, ${name}`}
                      />
                    </div>
                  </div>
                  <div style={{ maxWidth: '110px', minWidth: '110px' }}>
                    <div><small>{item.market}</small></div>
                    <div><strong style={{ fontSize: '0.9em' }}>{item.korean_name}</strong></div>
                  </div>
                  <div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>주</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'w1.rbi.macd') ? '🟢' : (_.get(meta, 'w1.rbi.r_macd') ? '🔴' : (_.get(meta, 'w1.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>일</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'd1.rbi.macd') ? '🟢' : (_.get(meta, 'd1.rbi.r_macd') ? '🔴' : (_.get(meta, 'd1.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>4시</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm240.rbi.macd') ? '🟢' : (_.get(meta, 'm240.rbi.r_macd') ? '🔴' : (_.get(meta, 'm240.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>1시</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm60.rbi.macd') ? '🟢' : (_.get(meta, 'm60.rbi.r_macd') ? '🔴' : (_.get(meta, 'm60.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>30분</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm30.rbi.macd') ? '🟢' : (_.get(meta, 'm30.rbi.r_macd') ? '🔴' : (_.get(meta, 'm30.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>15분</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm15.rbi.macd') ? '🟢' : (_.get(meta, 'm15.rbi.r_macd') ? '🔴' : (_.get(meta, 'm15.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>3분</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm3.rbi.macd') ? '🟢' : (_.get(meta, 'm3.rbi.r_macd') ? '🔴' : (_.get(meta, 'm3.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                  <div style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center' }}>
                    <div><small style={{ fontSize: '0.8em' }}>1분</small></div>
                    <div style={{ fontSize: '1.1em' }}>{_.get(meta, 'm1.rbi.macd') ? '🟢' : (_.get(meta, 'm1.rbi.r_macd') ? '🔴' : (_.get(meta, 'm1.rbi.obv') ? '⚪' : '⚫'))}</div>
                  </div>
                </Item>
              )
            })}
          </Item.Group>
        </div>
      </>
    )
  }

  componentWillUnmount() {
    if (this.timer) { window.clearInterval(this.timer) }
  }
}

TableWidget.propTypes = {
  handleCoin: PropTypes.func
}

TableWidget.defaultProps = {
  handleCoin: () => {}
}

export default TableWidget