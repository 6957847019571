const twitters = [
   // @2023.02.23, 확인완료
  { "market": "KRW-BTC", "twitter": "Bitcoin" },
  { "market": "KRW-ETH", "twitter": "ethereum" },
  { "market": "KRW-BCH", "twitter": "bitcoincashorg" },
  { "market": "KRW-AAVE", "twitter": "AaveAave" },
  { "market": "KRW-BSV", "twitter": "_BitcoinSV" },
  { "market": "KRW-SOL", "twitter": "solana" },
  { "market": "KRW-ETC", "twitter": "eth_classic" },
  { "market": "KRW-AVAX", "twitter": "avalancheavax" },
  { "market": "KRW-BTG", "twitter": "bitcoingold" },
  { "market": "KRW-STRK", "twitter": "StrikeFinance" },
  { "market": "KRW-APT", "twitter": "AptosLabs" },
  { "market": "KRW-ATOM", "twitter": "cosmos" },
  { "market": "KRW-NEO", "twitter": "Neo_Blockchain" },
  { "market": "KRW-GAS", "twitter": "Neo_Blockchain" },
  { "market": "KRW-AXS", "twitter": "AxieInfinity" },
  { "market": "KRW-REP", "twitter": "AugurProject" },
  { "market": "KRW-LINK", "twitter": "chainlink" },
  { "market": "KRW-DOT", "twitter": "Polkadot" },
  { "market": "KRW-QTUM", "twitter": "qtum" },
  { "market": "KRW-STEEM", "twitter": "steemit" },
  { "market": "KRW-SBD", "twitter": "steemit" },
  { "market": "KRW-WAVES", "twitter": "wavesprotocol" },
  { "market": "KRW-NEAR", "twitter": "NEARProtocol" },
  { "market": "KRW-TON", "twitter": "Tokamak_Network" },
  { "market": "KRW-OMG", "twitter": "bobanetwork" },
  { "market": "KRW-KAVA", "twitter": "kava_platform" }, // 계정이 사라진듯 @2023.02.23
  { "market": "KRW-XTZ", "twitter": "tezos" },
  { "market": "KRW-MATIC", "twitter": "0xPolygon" },
  { "market": "KRW-MTL", "twitter": "metalpaysme" },
  { "market": "KRW-FLOW", "twitter": "flow_blockchain" },
  { "market": "KRW-AQT", "twitter": "Alphaquark_" },
  { "market": "KRW-EOS", "twitter": "EOSnFoundation" },  // 변동됨 @2023.02.23
  { "market": "KRW-LSK", "twitter": "LiskHQ" },
  { "market": "KRW-THETA", "twitter": "Theta_Network" },
  { "market": "KRW-TFUEL", "twitter": "Theta_Network" },
  { "market": "KRW-KNC", "twitter": "KyberNetwork" },
  { "market": "KRW-DAWN", "twitter": "DawnProtocol" },
  { "market": "KRW-CELO", "twitter": "CeloOrg" },
  { "market": "KRW-CBK", "twitter": "CobakOfficial" },
  { "market": "KRW-SAND", "twitter": "TheSandboxGame" },
  { "market": "KRW-STX", "twitter": "Stacks" },
  { "market": "KRW-MANA", "twitter": "decentraland" },
  { "market": "KRW-1INCH", "twitter": "1inch" },
  { "market": "KRW-STRAX", "twitter": "stratisplatform" },
  { "market": "KRW-PUNDIX", "twitter": "PundiXLabs" },
  { "market": "KRW-ENJ", "twitter": "enjin" },
  { "market": "KRW-SRM", "twitter": "ProjectSerum" },
  { "market": "KRW-MFT", "twitter": "HifiFinance" },
  { "market": "KRW-GMT", "twitter": "Stepnofficial" },
  { "market": "KRW-STORJ", "twitter": "storj" },
  { "market": "KRW-HIVE", "twitter": "hiveblocks" },
  { "market": "KRW-GRS", "twitter": "GroestlcoinTeam" },
  { "market": "KRW-XRP", "twitter": "Ripple" },
  { "market": "KRW-ADA", "twitter": "Cardano" },
  { "market": "KRW-ARK", "twitter": "ArkEcosystem" },
  { "market": "KRW-HUNT", "twitter": "steemhunt" },
  { "market": "KRW-ONT", "twitter": "OntologyNetwork" },
  { "market": "KRW-ONG", "twitter": "OntologyNetwork" },
  { "market": "KRW-SXP", "twitter": "SolarNetwork" }, // @2023.02.23 변경됨
  { "market": "KRW-MLK", "twitter": "milk_alliance" },
  { "market": "KRW-BAT", "twitter": "AttentionToken" },
  { "market": "KRW-GLM", "twitter": "golemproject" },
  { "market": "KRW-ALGO", "twitter": "AlgoFoundation" },
  { "market": "KRW-ZRX", "twitter": "0xProject" },
  { "market": "KRW-PLA", "twitter": "playdapp_io" },
  { "market": "KRW-IOTA", "twitter": "iota" },
  { "market": "KRW-ICX", "twitter": "helloiconworld" },
  { "market": "KRW-BORA", "twitter": "bora_ecosystem" },
  { "market": "KRW-POWR", "twitter": "PowerLedger_io" },
  { "market": "KRW-POLY", "twitter": "PolymeshNetwork" },
  { "market": "KRW-NU", "twitter": "NuCypher" },
  { "market": "KRW-CHZ", "twitter": "Chiliz" },
  { "market": "KRW-CVC", "twitter": "civickey" },
  { "market": "KRW-HUM", "twitter": "Humanscape_io" },
  { "market": "KRW-ARDR", "twitter": "ArdorPlatform" },
  { "market": "KRW-XLM", "twitter": "StellarOrg" },
  { "market": "KRW-DOGE", "twitter": "dogecoin" },
  { "market": "KRW-MOC", "twitter": "TheMossland" },
  { "market": "KRW-AERGO", "twitter": "aergo_io" },
  { "market": "KRW-ELF", "twitter": "aelfblockchain" },
  { "market": "KRW-WAXP", "twitter": "WAX_io" },
  { "market": "KRW-HBAR", "twitter": "hedera" },
  { "market": "KRW-CRO", "twitter": "cryptocom" },
  { "market": "KRW-UPP", "twitter": "UPPSentinel" },
  { "market": "KRW-FCT2", "twitter": "FirmaChain" },
  { "market": "KRW-TRX", "twitter": "trondao" },
  { "market": "KRW-LOOM", "twitter": "loomnetwork" },
  { "market": "KRW-ANKR", "twitter": "ankr" },
  { "market": "KRW-STPT", "twitter": "STP_Networks" },
  { "market": "KRW-XEM", "twitter": "nemofficial" },
  { "market": "KRW-DKA", "twitter": "dKargo_Official" },
  { "market": "KRW-T", "twitter": "TheTNetwork" },
  { "market": "KRW-ZIL", "twitter": "zilliqa" },
  { "market": "KRW-ORBS", "twitter": "orbs_network" },
  { "market": "KRW-META", "twitter": "MetadiumK" },
  { "market": "KRW-JST", "twitter": "DeFi_JUST" },
  { "market": "KRW-SNT", "twitter": "ethstatus" },
  { "market": "KRW-VET", "twitter": "vechainofficial" },
  { "market": "KRW-SSX", "twitter": "somesinglovers" },
  { "market": "KRW-MED", "twitter": "_MediBloc" },
  { "market": "KRW-IOST", "twitter": "IOST_Official" },
  { "market": "KRW-QKC", "twitter": "Quark_Chain" },
  { "market": "KRW-AHT", "twitter": "_aha_official" },
  { "market": "KRW-IQ", "twitter": "everipedia" }, // 대표사진이 없음 @2023.02.23
  { "market": "KRW-RFR", "twitter": "Refereum" },
  { "market": "KRW-STMX", "twitter": "stormxio" },
  { "market": "KRW-TT", "twitter": "ThunderCoreLab" },
  { "market": "KRW-CRE", "twitter": "carryprotocol" },
  { "market": "KRW-MVL", "twitter": "mvlchain" },
  { "market": "KRW-SC", "twitter": "Sia__Foundation" },
  { "market": "KRW-BTT", "twitter": "BitTorrent" },
  { "market": "KRW-MBL", "twitter": "moviebloc" },
  { "market": "KRW-XEC", "twitter": "eCashOfficial" }
]

export default twitters