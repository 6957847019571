import React from 'react'

import App from './App'
import MobileApp from './MobileApp'

import { useWindowSize } from 'react-hooks-window-size'
import { detectAnyAdblocker } from 'just-detect-adblock'

const Root = () => {
  const size = useWindowSize() || {}

  const [checkBlocked, setCheckBlocked] = React.useState({ checked: false, detected: false })

  if (!checkBlocked.checked) {
    detectAnyAdblocker()
      .then((detected) => setCheckBlocked({ checked: true, detected }))
      .catch(e => setCheckBlocked({ checked: true, detected: false }))
    return null
  }
  if (checkBlocked.detected) {
    return (
      <div style={{ padding: '3rem 1rem' }}>
        <div style={{ padding: '3rem', textAlign: 'center', fontSize: '10em', lineHeight: '2em' }}>
          ☕
        </div>
        <header style={{ fontSize: '1.5rem', padding: '1rem' }}>
          <strong>코인알비아이 이용안내</strong>
        </header>
        <div style={{ padding: '1rem' }}>
          구글 광고 차단기능을 해제해주세요.<br/>
          코인RBI 기능을 사용이 가능합니다. 브라우저 설정에 광고 차단기능을 해제 또는 코인알비아이 광고 허용해주셔야 사용이 가능합니다. 불편을 끼쳐드려 대단히 죄송합니다.
        </div>
        <div style={{ padding: '1rem' }}>
          광고 차단어플리케이션에서 coinrbi.com 도메인을 허용해주시거나, 설정에서 광고차단기능 해제를 눌러주세요. 본사이트의 서버 운영 유지에 큰 도움이 됩니다.
        </div>
      </div>
    )
  }

  return (
    <>
      {size.width > 680
        ? <App />
        : <MobileApp />}
    </>
  )
}

export default Root