import React from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import AdSense from 'react-adsense'
import qs from 'query-string'

// import YouTube from 'react-youtube'
import { Helmet } from "react-helmet"
// import { MiniChart } from "react-tradingview-embed"
import { useWindowSize } from 'react-hooks-window-size'

// import Modal from './Modal'

import twitters from '../twitters'
import marketCoins from '../markets'

import HomeHeader from './HomeHeader'

import DashboardWidget from '../widgets/DashboardWidget'
import CrossWidgets from '../widgets/CrossWidgets'
import MobileTableWidget from '../widgets/MobileTableWidget'

// https://t.me/stylecointeam

const Page = styled.div`
  position: relative; box-sizing: border-box;
  padding-bottom: 6rem;
`

Page.Grid = styled.div`
  position: relative; display: flex;
  & > * { margin: 10px; }
  & > div.aside {
    display: block; position: absolute; transition: all 0.3s;
    @media screen and (max-width: 2000px) { display: none; }
  }
`

Page.Cover = styled.div`
  position: fixed; left: 0; top: 0;
  width: 100%; height: 100%; z-index: 8999;
  background: #111; opacity: 0.97;
`

Page.Drawer = styled.div`
  position: fixed; left: 0; bottom: -90%; margin-bottom: 100px;
  width: 100%; height: 90%; max-height: 90%;
  transition: all 0.3s;
  overflow-y: hidden;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
  z-index: 9999; background: rgba(1,1,1, 0.95);
  &.active { bottom: 0;  margin-bottom: 0; background: #141722; overflow-y: scroll; }
  & > div {
    position: relative; box-sizing: border-box;
  }
`

Page.Header = styled.header`
  position: relative; font-size: 1.3rem; padding: 0.75rem 0;
  text-align: center;
`

Page.ChartButtons = styled.div`
  position: relative;
  & > div {
    display: flex;
    max-width: 95%; margin: 0 auto;
    background: white; overflow: auto; flex-wrap: nowrap;
    border-radius: 0.35rem;
    & > a {
      color: #121214; min-width: max-content;
      font-weight: 700;
      display: block; color: 
      white-space: pre; padding: 0.55rem 0.85rem;
      &.active {
        background: skyblue;
        &:hover { opacity: 1; }
      }
      &:hover { opacity: 1; }
    }
  }
`

Page.Lead = styled.header`
  position: relative; font-size: 1.2rem; padding: 0.25rem 0.75rem;
`

Page.Message = styled.header`
  position: relative; padding: 1rem;
  display: flex; justify-content: space-between;
  & > a { font-weight: 700; color: yellow; }
  & > div:first-child { flex: 1 1 100%; }
  & > div:last-child { min-width: max-content; white-space: pre-line; }
`

Page.CoverBox = styled.section`
  position: relative; box-sizing: border-box;
  & > div.main { position: relative; z-index: 1; box-sizing: border-box; padding: 0.5rem; }
  & > div.cover {
    position: absolute; box-sizing: border-box; display: block; z-index: 999;
    left: 0; right: 0; width: 100%; height: 100%;
    opacity: 0; background: #fafafa;
  }
`

const getTwitter = (symbol) => {
  const name = symbol.replace('UPBIT:', '').replace('KRW', '')
  return twitters.find(t => t.market === `KRW-${name}`)
}

const Home = ({ match = {} }) => {
  ReactGA.pageview(window.location.pathname + window.location.search)

  const search = window.location.search ? qs.parse(window.location.search) : {}
  const initialMarket = (match.params || { market: null }).market
    ? decodeURIComponent(match.params.market)
    : (search.market ? decodeURIComponent(search.market) : 'CRYPTOCAP:TOTAL')

  if (search.mode) { window.localStorage.setItem('mode', search.mode) }
  const mode = window.localStorage.getItem('mode') || 'trade'

  const [symbol, setSymbol] = React.useState(initialMarket)
  const goSymbol = (symbol) => [window.localStorage.setItem('mode', 'trade'), window.location.href = `/${encodeURIComponent(symbol)}`]
  
  const size = useWindowSize()
  const chartWidth = Math.round(size.width * 0.95)
  
  // 현재 접속한 마켓에 대한 정보를 저장하기
  let currentMarket = false, currentCoin = initialMarket && initialMarket !== 'CRYPTOCAP:TOTAL' ? initialMarket.replace('UPBIT:', '').replace('KRW', '') : false
  if (currentCoin) { currentMarket = marketCoins.find(coin => coin.market === `KRW-${currentCoin}`) }

  // 트레이딩모드에 대한 값 지정하기
  const ChartSet = window.localStorage.getItem('ChartSet') || 'basic'

  // 타점맵오픈기능
  const [dashboardDrawer, setDashboardDrawer] = React.useState(false)

  return (
    <Page>
      <Helmet>
        {currentMarket ? <title>{currentMarket.korean_name}({currentMarket.english_name}, {currentCoin}) 차트 - 코알(COINRBI)</title> : null}
        {!currentMarket ? <title>코알(COINRBI) - 비트코인, 이더리움 차트</title> : null}
      </Helmet>

      <HomeHeader />
      
      {mode === 'trade' ? (
        <>
          <div style={{ padding: '0.75rem 0' }}>
            <Page.ChartButtons>
              <div>
                {currentMarket && (currentMarket.market.indexOf('KRW-BTC') < 0)
                  ? (<a href="#updateChartSymbol" className={symbol.indexOf(`UPBIT:${currentCoin}KRW`) === 0 ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol(symbol)]}>🎯 {currentMarket.korean_name} <small>차트</small></a>)
                  : null}

                <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:TOTAL' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:TOTAL')]}>🌎 시총</a>
                <a href="#updateChartSymbol" className={symbol === '1/CRYPTOCAP:BTC.D*FOREXCOM:NSXUSD*BITFINEX:BTCUSDLONGS/BITFINEX:BTCUSDSHORTS' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('1/CRYPTOCAP:BTC.D*FOREXCOM:NSXUSD*BITFINEX:BTCUSDLONGS/BITFINEX:BTCUSDSHORTS')]}>🚥 판세</a>
                <a href="#updateChartSymbol" className={symbol === 'BINANCEUS:BTCUSDT' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BINANCEUS:BTCUSDT')]}>🍔 버거비트</a>
                <a href="#updateChartSymbol" className={symbol === 'UPBIT:BTCKRW' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('UPBIT:BTCKRW')]}>🥓 김치대장</a>
                <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:BTC.D' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:BTC.D')]}>🍕 비트도미</a>
                <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:USDT.D' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:USDT.D')]}>🔥 테더도미</a>
                <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:OTHERS.D' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:OTHERS.D')]}>🌱 알트도미</a>
                <a href="#updateChartSymbol" className={symbol === 'FOREXCOM:NSXUSD' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('FOREXCOM:NSXUSD')]}>🥰 나스닥</a>
                <a href="#updateChartSymbol" className={symbol === '(UPBIT:BTCKRW/(BINANCEUS:BTCUSDT*FX_IDC:USDKRW))*100-100' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('(UPBIT:BTCKRW/(BINANCEUS:BTCUSDT*FX_IDC:USDKRW))*100-100')]}>🚨 김프</a>
                <a href="#updateChartSymbol" className={symbol === 'BITFINEX:BTCUSDSHORTS/BITFINEX:BTCUSDLONGS' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITFINEX:BTCUSDSHORTS/BITFINEX:BTCUSDLONGS')]}>🪓 롱숏</a>
                <a href="#updateChartSymbol" className={symbol === 'UPBIT:BTCKRW/BITHUMB:BTCKRW' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('UPBIT:BTCKRW/BITHUMB:BTCKRW')]}>🥊 업비트/빗썸</a>
                <a href="#updateChartSymbol" className={symbol === 'BITFINEX:BTCUSDLONGS' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITFINEX:BTCUSDLONGS')]}>🌋 롱</a>
                <a href="#updateChartSymbol" className={symbol === 'BITFINEX:BTCUSDSHORTS' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITFINEX:BTCUSDSHORTS')]}>🌊 숏</a>
                <a href="#updateChartSymbol" className={symbol === 'TSX:BITI' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('TSX:BITI')]}>🧛‍♂️ BITI</a>
                <a href="#updateChartSymbol" className={symbol === 'BITMEX:XBTUSD' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITMEX:XBTUSD')]}>🐋 BMXBT</a>
                <a href="#updateChartSymbol" className={symbol === 'FX_IDC:USDKRW' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('FX_IDC:USDKRW')]}>💸 환율</a>
              </div>
            </Page.ChartButtons>
          </div>

          {mode === 'trade' && currentMarket ? <MobileTableWidget pick={currentMarket} /> : null}
          
          <div style={{ paddingBottom: '0.75rem' }}>
            <Page.CoverBox>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <iframe src={`/chart?name=${symbol}&interval=${(symbol.indexOf('/') < 0) ?'240' : '1D'}&width=${chartWidth}&height=400&chartSet=${ChartSet}&hiddenSidebar=true`} height={400} width={chartWidth} frameBorder={0} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <a
                  href="#ChartSet"
                  onClick={e => {
                    window.localStorage.setItem('ChartSet', 'basic')
                    return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                  }}
                  style={ChartSet === 'basic' ? { fontWeight: '900', color: 'skyblue', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                >
                  MACD
                </a>
                <a
                  href="#ChartSet"
                  onClick={e => {
                    window.localStorage.setItem('ChartSet', 'rsi')
                    return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                  }}
                  style={ChartSet === 'rsi' ? { fontWeight: '900', color: 'skyblue', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                >
                  RSI
                </a>
                <a
                  href="#ChartSet"
                  onClick={e => {
                    window.localStorage.setItem('ChartSet', 'wr')
                    return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                  }}
                  style={ChartSet === 'wr' ? { fontWeight: '900', color: 'skyblue', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                >
                  W%R
                </a>
                <a
                  href="#ChartSet"
                  onClick={e => {
                    window.localStorage.setItem('ChartSet', 'obv')
                    return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                  }}
                  style={ChartSet === 'obv' ? { fontWeight: '900', color: 'skyblue', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                >
                  OBV
                </a>
                <a
                  href="#ChartSet"
                  onClick={e => {
                    window.localStorage.setItem('ChartSet', 'all')
                    return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                  }}
                  style={ChartSet === 'all' ? { fontWeight: '900', color: 'skyblue', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                >
                  ALL
                </a>
              </div>
            </Page.CoverBox>
          </div>
        </>
      ) : null}
    
    <div style={{ minWidth: '100%' }}>
      <AdSense.Google client='ca-pub-2415806529829125' slot='6493897876' style={{ display: 'block' }} format='auto' responsive='true' />
    </div>
    
      <MobileTableWidget />

      {mode === 'trade' && symbol.indexOf('UPBIT:') !== 0 ? 
        <>
          <div style={{ padding: '1rem 0' }}>
            <div>
              <CrossWidgets
                handleCoin={(name, stay) => {
                  if (stay) {
                    setSymbol(name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`)
                  } else {
                    window.location.href= `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
                  }
                }}
              />
            </div>
          </div>
        </>
      : null}

      {mode === 'trade' ? (
        <>
          {dashboardDrawer ? <Page.Cover onClick={e => [e.stopPropagation(), setDashboardDrawer(false)]} /> : null}
          <Page.Drawer
            className={dashboardDrawer ? "active" : null}
            onClick={e => {
              if (!dashboardDrawer) { setDashboardDrawer(true) }
              return [e.stopPropagation()]
            }}
          >
            <div>
              <div style={{ padding: '0.5rem' }}>
                <DashboardWidget
                  market={initialMarket}
                  handleCoin={(name, stay, event) => {
                    if (stay === 'state') {
                      setSymbol(name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`)
                    } else if (stay === 'href' && event) {
                      event.target.href = `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
                    } else {
                      window.location.href= `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
                    }
                  }}
                /> 
              </div>
            </div>
          </Page.Drawer>
        </>
      ) : null}
    </Page>
  )
}

export default Home
