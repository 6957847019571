import React from 'react'
import styled from 'styled-components'
import markets from '../markets'
import _ from 'lodash'
import qs from 'query-string'

import moment from 'moment'
import 'moment/locale/ko'

import Players from '../components/Players'
import DashboardWidget from '../widgets/DashboardWidget'
import CrossWidgets from '../widgets/CrossWidgets'

const Page = styled.div`
  position: relative; box-sizing: border-box;
  max-width: 1400px; margin: 0 auto;
`

const Grid = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; z-index: 1;
  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
  }
`

Grid.Drawer = styled.aside`
  position: fixed; bottom: 0; right: 0; box-sizing: border-box;
  min-width: 480px; max-width: 480px; 
  min-height: 100%; max-height: 100%; z-index: 9999;
  overflow-y: auto; background: #0b0c11; 
  &::-webkit-scrollbar { display: none; }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  @media screen and (max-width: 680px) {
    min-width: 100%; max-width: 100%;
  }
`

Grid.Body = styled.section`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; z-index: 1;
  @media screen and (max-width: 680px) {
    min-width: 100%; max-width: 100%;    
  }
`

const Box = styled.div`
  position: relative; box-sizing: border-box;
  max-width: 480px; margin: 5rem auto;
  &.wide { max-width: 100%; margin: 0; }
  & > section {
    padding: 0.5rem 1rem; box-sizing: border-box;
    margin: 0 auto;
    & > header { font-size: 2em; font-weight: 900; }
    & > main { padding: 2rem 0; }
    & .btn {
      display: block; background: skyblue; padding: 0.75rem 1.75rem;
      font-size: 1.4em; font-size: 1.2em; font-weight: 900; color: #141722;
      border-radius: 0.35rem; box-sizing: border-box; text-align: center;
      &.dark { background: #121214; color: white; }
    }
    @media screen and (max-width: 680px) { font-size: 0.85em; margin: 0rem auto; }
  }
  @media screen and (max-width: 680px) {
    &.wide { max-width: 100%; margin: 0; }
    &.wide > section { margin: 0; padding: 0; }
    &.wide > section > main { margin: 0; padding: 0; }
  }
`

const Controls = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; padding: 2rem 0;
  & > div {
    flex: 1 1 100%; box-sizing: border-box;
    padding: 0.35rem;
    display: flex; position: relative;
    & > input {
      flex: 1 1 100%; min-width: 0; width: 100%; box-sizing: border-box;
      border-radius: 0.35rem;
      padding: 0.85rem 1rem; 
      border: 0; outline: none;
    }
    @media screen and (max-width: 680px) {
      padding: 0.85rem 0.35rem; flex: unset;
    }
  }
  @media screen and (max-width: 680px) {
    display: block;
  }
`

const Coin = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem; background: #121214; border-radius: 0.35rem;
  font-size: 0.81em;
  flex: 1 1 100%; min-width: 30.9%; max-width: 30.9%; margin: 0.5rem 0.99%; border: 3px solid #121214; opacity: 0.5;
  display: flex; justify-content: center; align-items: center;
  transition: all 0.3s; cursor: pointer; line-height: 1.35em;
  div.meta { flex: 1 1 100%; box-sizing: border-box; }
  div.icon { padding: 0.35rem; font-size: 1.5em; box-sizing: border-box; opacity: 0.5; }
  &:hover { opacity: 1; }
  &.active {
    opacity: 1;
    border: 3px solid skyblue;
    div.meta { color: skyblue; }
    div.icon { color: skyblue; opacity: 1; }
  }
  @media screen and (max-width: 680px) {
    min-width: 30.9%; max-width: 30.9%; 
    font-size: 0.7em; padding: 0.5rem 0.75rem;
    margin: 0.25rem 0.99%;
    div.icon { display: none; }
  }
`

Coin.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; flex-wrap: wrap; justify-content: center;
`

// 텔레그램에 인증코드를 보내는 작업과 작동여부
// me = { telegramAccount: '', password: '', status: 'activated', coins: ['XRP'] }
class Me extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      signed: false, tryAt: null,
      friend: {
        id: window.localStorage.getItem('id') || '',
        code: window.localStorage.getItem('code') || '',
        coins: [], players: [], customPlayers: []
      },
      mode: 'view',
    }
    this.doSign = this.doSign.bind(this)
    this.doSave = this.doSave.bind(this)
  }

  componentDidMount() {
    if (_.get(this, 'state.friend.id') && _.get(this, 'state.friend.code')) {
      this.doSign()
    }
  }

  // 1. 인증번호를 보내 현재 계정주가 맞는지 체크
  async doRequestCode() {
    if (!_.get(this, 'state.friend.id')) { return alert(`아이디를 입력해주셔야 진행이 가능합니다.`) }

    const curAt = new Date(), curMt = moment(curAt)

    if (this.state.tryAt) {
      const tryMt = moment(this.state.tryAt)
      if (curMt.diff(tryMt, 'minutes') <= 1) { return alert(`1분 뒤에 진행이 가능합니다.`) }
    }

    return await fetch(`https://api.coinrbi.com/signedTelegram/${_.get(this, 'state.friend.id')}`)
      .then((response) => response.json().catch(e => { return { error: true } }))
      .then((data) => {
        if (data.error) { return alert(data.message || `에러가 발생했습니다.`) }
        alert(`인증코드가 발급되었습니다. 텔레그램을 확인해주세요!`)
        this.setState({ friend: { id: _.get(this, 'state.friend.id'), code: '' }, signed: false, tryAt: new Date() })
      })
      .catch(e => {
        alert(e.message || `에러가 발생했습니다.`)
        this.setState({ friend: { id: _.get(this, 'state.friend.id'), code: '' }, signed: false, tryAt: new Date() })
      })
  }

  // 1. 인증번호를 보내 현재 계정주가 맞는지 체크
  async doSign() {
    if (!_.get(this, 'state.friend.id')) { return alert(`아이디를 입력해주셔야 진행이 가능합니다.`) }
    if (!_.get(this, 'state.friend.code')) { return alert(`아이디를 입력해주셔야 진행이 가능합니다.`) }

    return await fetch(`https://api.coinrbi.com/getTelegram/${_.get(this, 'state.friend.id')}/${_.get(this, 'state.friend.code')}`)
      .then((response) => response.json().catch(e => { return { error: true } }))
      .then((data) => {
        if (data.error) { return alert(data.message || `에러가 발생했습니다.`) }
        window.localStorage.setItem('id', _.get(this, 'state.friend.id'))
        window.localStorage.setItem('code', _.get(this, 'state.friend.code'))
        this.setState({ friend: data.friend, signed: true })
      })
      .catch(e => {
        alert(e.message || `에러가 발생했습니다.`)
        this.setState({ friend: { id: _.get(this, 'state.friend.id'), code: _.get(this, 'state.friend.code') }, signed: false })
      })
  }

  // 2. 작동여부, 필터(종목) 서버측에 저장해두기
  async doSave() {
    const headers = new Headers()
    headers.set('Content-Type', 'application/json')
    const body = { coins: _.get(this, 'state.friend.coins') || [] }
    return await fetch(`https://api.coinrbi.com/savedTelegram/${_.get(this, 'state.friend.id')}/${_.get(this, 'state.friend.code')}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(body)
      })
      .then(res => res.json())
      .then((data) => {
        if (data.error) { return alert(data.message || `에러가 발생했습니다.`) }
        this.setState({ mode: 'view' })
      })
      .catch(e => {
        alert(e.message || `에러가 발생했습니다.`)
        this.setState({ mode: 'view' })
      })
  }


  render() {
    const isSigned = _.get(this, 'state.signed') ? true : false
    const coins = _.get(this, 'state.friend.coins') || []
    const players = _.get(this, 'state.friend.players') || []
    const sortedMarkets = [...markets]
    sortedMarkets.sort((a, b) => a.korean_name > b.korean_name ? 1 : -1)

    const search = window.location.search ? qs.parse(window.location.search) : {}

    return (
      <Page>
        <Grid>
          <Grid.Body>
            {!isSigned ? (
              <Box>
                <section>
                  <header>
                    코알 줍줍일기
                  </header>
                  <main>
                    <div> 
                      코알 줍줍일기 친구 신청을 통해 텔레그램를 통해서 희망하는 종목만을 실시간으로 구독 할 수 있습니다.
                      골든크로스와 데드크로스 위치를 체크하여 희망하는 종목이 포함된 타점대역대를 체크해서 알려줍니다. 100명 정도만 사용가능합니다.
                      더 많은 유저를 수용하려하였으나, 서버의 무리가 갈 수 있는 부분이 있는점 양해 말씀 부탁드립니다. 매일 0시에 0개종목을 설정하였거나 3일간 설정 정보가 업데이트 되지 않은 유저는 삭제됩니다.
                      간혹 인증코드가 발급되지 않으면, <strong style={{ color: 'yellow' }}>coinrbi_corine</strong> 복사하셔서 텔레그램에 친구 추가하시고 메시지 하나를 보내주시면 친구로 인정됩니다. 이후 인증코드 발급이 되는 경우도 있습니다. (본인 계정이 친구 아니면 모르는 사람에게 대화를 받지 않는 설정을 해둔경우나 초기상태인 경우 안될 수 있습니다.)
                    </div>
                    <Controls>
                      <div>
                        <input
                          type="text"
                          placeholder={`영문아이디(예, coinrbi)`}
                          value={_.get(this, 'state.friend.id') || ``}
                          onChange={e => {
                            const next = { ...(_.get(this, 'state.friend') || {}), id: `${e.target.value}`.replace('@', '').trim() }
                            this.setState({ friend: next })
                          }}
                        />
                      </div>
                      <div>
                        <input
                          type="password"
                          placeholder={`발급 받은 6자리 인증번호`}
                          value={_.get(this, 'state.friend.code') || ``}
                          onChange={e => {
                            const next = { ...(_.get(this, 'state.friend') || {}), code: `${e.target.value}`.trim() }
                            this.setState({ friend: next })
                          }}
                        />
                      </div>
                    </Controls>
                    <div style={{ padding: '1rem 0', textAlign: 'right' }}>
                      <a
                        href="#doSubmit"
                        className="btn"
                        onClick={e => {
                          const hasCode = _.get(this, 'state.friend.code') ? true : false
                          return [e.stopPropagation(), e.preventDefault(), !hasCode ? this.doRequestCode() : this.doSign()]
                        }}
                      >
                        {!_.get(this, 'state.friend.code') ? `인증요청` : `로그인`}
                      </a>
                    </div>
                  </main>
                </section>
              </Box>
            ) : null}
            {isSigned ? (
              <Box className="wide">
                <section>
                  <div>
                    <div style={{ padding: '1rem' }}>
                    </div>
                    <div style={{ padding: '1rem' }}>
                      <a
                        href="#doSubmit"
                        className="btn"
                        onClick={e => [e.stopPropagation(), e.preventDefault(), this.setState({ mode: 'edit' })]}
                      >
                        마이코알 관리
                      </a>
                    </div>
                  </div>
                  {players.length ? (
                    <main>
                      <Players mode={'me'} />
                    </main>
                  ) : null}
                  <main>
                    <CrossWidgets coins={coins} />
                  </main>
                  <main>
                    <div style={{ padding: '0.5rem' }}>
                      <DashboardWidget
                        coins={coins}
                        me={true}
                        handleCoin={(name, stay, event) => {
                          window.location.href= `/me`
                        }}
                      />
                    </div>
                  </main>
                </section>
              </Box>
              ) : null}
          </Grid.Body>
          {this.state.mode === 'edit' ? (
            <Grid.Drawer>
              <Box className="wide">
                <section>
                  <main>
                    <Coin.Group>
                      {sortedMarkets.map(({ market, korean_name }, marketIdx) => {
                        const coin = market.replace('KRW-', '')
                        return (
                          <Coin
                            key={`market_${marketIdx}`}
                            className={(_.get(this, 'state.friend.coins') || []).includes(coin) ? `active` : ''}
                            onClick={e => {
                              const friend = JSON.parse(JSON.stringify(_.get(this, 'state.friend') || {}))
                              friend.coins = friend.coins.includes(coin)
                                ? friend.coins.filter(_coin => coin !== _coin)
                                : [...friend.coins, coin]
                              return this.setState({ friend })
                            }}
                          >
                            <div className="meta">
                              <div style={{ fontWeight: '700' }}>{korean_name}</div>
                              <div><small style={{ color: '#777' }}>{market}</small></div>
                            </div>
                            <div className={"icon"}>
                              <i className="ri-check-line"></i>
                            </div>
                          </Coin>
                        )
                      })}
                    </Coin.Group>
                    <div style={{ padding: '1rem' }}>
                      <a
                        href="#doSubmit"
                        className="btn"
                        onClick={e => [e.stopPropagation(), e.preventDefault(), this.doSave()]}
                      >
                        저장 및 닫기
                      </a>
                    </div>
                    <div style={{ padding: '1rem' }}>
                      <a
                        href="#doSubmit"
                        className="btn dark"
                        onClick={e => {
                          window.localStorage.removeItem('id')
                          window.localStorage.removeItem('code')
                          return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                        }}
                      >
                        로그아웃
                      </a>
                    </div>
                  </main>
                </section>
              </Box>
            </Grid.Drawer>
          ) : null}
        </Grid>
      </Page>
    )
  }
}

export default Me
