import React from 'react'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router'
import qs from 'query-string'
import styled from 'styled-components'
import { useWindowSize } from 'react-hooks-window-size'

import moment from 'moment'
import 'moment/locale/ko'
import _ from 'lodash'

import ReactTypingEffect from 'react-typing-effect'
import TimelineWidget from '../widgets/TimelineWidget'

import marketCoins from '../markets'

const DesktopHeader = styled.div`
  position: relative; font-size: 1.5rem; padding: 1rem ;
  display: flex; justify-content: space-between; box-sizing: border-box;
`

const MobileHeader = styled.div`
  position: relative; font-size: 2rem; box-sizing: border-box; padding: 0.75rem 0;
  & > div { padding: 0.65rem 0; text-align: center; }
`

const HomeHeader = ({ match }) => {
  const size = useWindowSize()
  
  const search = window.location.search ? qs.parse(window.location.search) : {}

  if (search.mode) { window.localStorage.setItem('mode', search.mode) }
  const mode = window.localStorage.getItem('mode') || 'trade'

  const initialMarket = (match.params || { market: null }).market
    ? decodeURIComponent(match.params.market)
    : (search.market ? decodeURIComponent(search.market) : 'CRYPTOCAP:TOTAL')

  let currentMarket = false, currentCoin = initialMarket && initialMarket !== 'CRYPTOCAP:TOTAL' ? initialMarket.replace('UPBIT:', '').replace('KRW', '') : false
  if (currentCoin) { currentMarket = marketCoins.find(coin => coin.market === `KRW-${currentCoin}`) }

  return (
    <>
      {size.width > 640 
          ? (
            <DesktopHeader>
              {currentMarket ? (
                <div className="site">
                  <strong>{currentMarket.korean_name}({currentMarket.english_name}, {currentCoin})</strong> - 암호화폐 고래·타점 코알(코인알비아이)
                </div>
              ) : null}
              {!currentMarket ? (
                <div className="site">
                  <a href="/" style={{ fontWeight: 900, textDecoration: 'underline', textDecorationColor: 'rgba(0, 255, 255, 1)' }}>COINRBI</a>
                </div>
              ) : null}
              <div className="btns">
                <a
                  href={`/CRYPTOCAP:TOTAL?mode=trade`}
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    window.localStorage.setItem('mode', 'trade')
                    return [e.stopPropagation(), ReactGA.event({ category: 'Move', action: `CRYPTOCAP`, label: `move to CRYPTOCAP TOTAL` })]
                  }}
                >
                    <i className="ri-home-fill"></i>
                </a>
                <a
                  href={`/?mode=static`}
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), ReactGA.event({ category: 'Move', action: `setViewport`, label: `move to statics` })]
                  }}
                >
                  <i className="ri-slideshow-line"></i>
                </a>
                <a
                  href="https://cafe.naver.com/coinrbi/3045"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Google Youtube`, label: `move to Help` }), window.open('https://cafe.naver.com/coinrbi/3045')]
                  }}
                >
                    <i className="ri-questionnaire-line"></i>
                </a>
                <a
                  href="https://open.kakao.com/o/gLZuhJzd"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `KakaoTalk`, label: `move to KakaoTalk` }), window.open('https://open.kakao.com/o/gLZuhJzd')]
                  }}
                >
                    <i className="ri-kakao-talk-fill"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCpCwgaJju6Ag1yyUzVbMVlg"
                  target="_blank"
                  style={{ marginRight: '0.75rem', color: 'red' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Google Youtube`, label: `move to Google 코인RBI Youtube` }), window.open('https://www.youtube.com/channel/UCpCwgaJju6Ag1yyUzVbMVlg')]
                  }}
                >
                    <i className="ri-youtube-fill"></i>
                </a>
                <a
                  href="https://cafe.naver.com/coinrbi"
                  target="_blank"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Naver Cafe`, label: `move to Naver Cafe` }), window.open('https://cafe.naver.com/coinrbi')]
                  }}
                >
                    <i className="ri-cup-line"></i>
                </a>
                <a
                  href="https://twitter.com/coinrbicom"
                  target="_blank"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    console.log(e.target)
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Twitter`, label: `move to Twitter` }), window.open('https://twitter.com/coinrbicom')]
                  }}
                >
                  <i className="ri-twitter-fill"></i>
                </a>
                <a
                  href="https://t.me/s/coinrbi"
                  target="_blank"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Telegram`, label: `move to Telegram` }), window.open('https://t.me/s/coinrbi')]
                  }}
                >
                  <i className="ri-telegram-fill"></i>
                </a>
                <a
                  href="https://t.me/stylecointeam"
                  target="_blank"
                  style={{ marginRight: '0.75rem', color: 'yellow' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Telegram`, label: `move to Telegram` }), window.open('https://t.me/s/coinrbi')]
                  }}
                >
                  <i className="ri-telegram-fill"></i>
                </a>
                <a
                  href={`/players`}
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    window.localStorage.setItem('mode', 'trade')
                    return [e.stopPropagation(), ReactGA.event({ category: 'Move', action: `go Players`, label: `move to Players` })]
                  }}
                >
                  <i className="ri-medal-line"></i>
                </a>
              </div>
            </DesktopHeader>
          )
          : (
            <MobileHeader>
              <div className="btns">
                <a
                  href={`/CRYPTOCAP:TOTAL?mode=trade`}
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    window.localStorage.setItem('mode', 'trade')
                    return [e.stopPropagation(), ReactGA.event({ category: 'Move', action: `CRYPTOCAP`, label: `move to CRYPTOCAP TOTAL` })]
                  }}
                >
                    <i className="ri-home-fill"></i>
                </a>
                <a
                  href="https://cafe.naver.com/coinrbi/3045"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Google Youtube`, label: `move to Help` }), window.open('https://cafe.naver.com/coinrbi/3045')]
                  }}
                >
                    <i className="ri-questionnaire-line"></i>
                </a>
                <a
                  href="/twitters?loop=true"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), ReactGA.event({ category: 'Move', action: `Twitter Achive`, label: `move to Twitter Achive` })]
                  }}
                >
                  <i className="ri-archive-line"></i>
                </a>
                <a
                  href="https://open.kakao.com/o/gLZuhJzd"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `KakaoTalk`, label: `move to KakaoTalk` }), window.open('https://open.kakao.com/o/gLZuhJzd')]
                  }}
                >
                    <i className="ri-kakao-talk-fill"></i>
                </a>
                <a
                  href={`/players`}
                  onClick={e => {
                    return [e.stopPropagation(), ReactGA.event({ category: 'Move', action: `Animal Rank`, label: `move to Animal Rank` })]
                  }}
                >
                  <i className="ri-medal-line"></i>
                </a>
              </div>
              <div className="btns">    
                <a
                  href="https://www.youtube.com/channel/UCpCwgaJju6Ag1yyUzVbMVlg"
                  target="_blank"
                  style={{ marginRight: '0.75rem', color: 'red' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Google Youtube`, label: `move to Google 코인RBI Youtube` }), window.open('https://www.youtube.com/channel/UCpCwgaJju6Ag1yyUzVbMVlg')]
                  }}
                >
                    <i className="ri-youtube-fill"></i>
                </a>
                <a
                  href="https://cafe.naver.com/coinrbi"
                  target="_blank"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Naver Cafe`, label: `move to Naver Cafe` }), window.open('https://cafe.naver.com/coinrbi')]
                  }}
                >
                    <i className="ri-cup-fill"></i>
                </a>
                <a
                  href="https://twitter.com/coinrbicom"
                  target="_blank"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    console.log(e.target)
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Twitter`, label: `move to Twitter` }), window.open('https://twitter.com/coinrbicom')]
                  }}
                >
                  <i className="ri-twitter-fill"></i>
                </a>
                <a
                  href="https://t.me/s/coinrbi"
                  target="_blank"
                  style={{ marginRight: '0.75rem' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Telegram`, label: `move to Telegram` }), window.open('https://t.me/s/coinrbi')]
                  }}
                >
                  <i className="ri-telegram-fill"></i>
                </a>
                <a
                  href="https://t.me/stylecointeam"
                  target="_blank"
                  style={{ color: 'yellow' }}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), ReactGA.event({ category: 'Move', action: `Telegram`, label: `move to Telegram` }), window.open('https://t.me/s/coinrbi')]
                  }}
                >
                  <i className="ri-telegram-fill"></i>
                </a>
              </div>
            </MobileHeader>
          )}
      <TimelineWidget />
    </>
  )
  
}

export default withRouter(HomeHeader)