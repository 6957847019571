import React from 'react'
import ReactGA from 'react-ga'
import styled, { css } from 'styled-components'

import AdSense from 'react-adsense'
import qs from 'query-string'
import { Helmet } from "react-helmet"
import { useWindowSize } from 'react-hooks-window-size'

import marketCoins from '../markets'

import HomeHeader from './HomeHeader'
import MiniChartsWidget from '../widgets/MiniChartsWidget'
import DashboardWidget from '../widgets/DashboardWidget'
import CrossWidgets from '../widgets/CrossWidgets'
import TableWidget from '../widgets/TableWidget'
import RbiTreeWidget from '../widgets/RbiTreeWidget'

const Page = styled.div`
  max-width: 1700px; min-width: 1700px; margin: 1rem auto;
  padding-bottom: 8rem;
`

Page.Grid = styled.div`
  position: relative; display: flex;
  & > * { margin: 10px; }
  & > div.aside {
    display: block; position: absolute; transition: all 0.3s;
    @media screen and (max-width: 2000px) { display: none; }
  }
`

Page.ChartButtons = styled.div`
  position: relative;
  & > div {
    display: flex; max-width: 100%; margin: 0 auto;
    background: white; overflow: auto; flex-wrap: nowrap;
    border-radius: 0.35rem;
    & > a {
      color: #121214; min-width: max-content;
      font-weight: 900;
      display: block; color: 
      white-space: pre; padding: 0.55rem 0.85rem;
      &:hover { opacity: 1; }
      &.active {
        background: #00FFFF;
        &:hover { opacity: 1; }
      }
    }
  }
`

Page.Header = styled.header`
  position: relative; font-size: 1.5rem; padding: 1rem;
  display: flex; justify-content: space-between;
`

const Block = styled.header`
  position: relative;  padding: 0.5rem;
  & > header { font-size: 1.5rem; padding: 1rem 0; }
`

const Home = ({ match = {} }) => {
  ReactGA.pageview(window.location.pathname + window.location.search)

  const search = window.location.search ? qs.parse(window.location.search) : {}

  const initialMarket = (match.params || { market: null }).market
    ? decodeURIComponent(match.params.market)
    : (search.market ? decodeURIComponent(search.market) : 'CRYPTOCAP:TOTAL')

  if (search.mode) { window.localStorage.setItem('mode',  search.mode) }
  const mode = window.localStorage.getItem('mode') || 'trade'

  const [symbol, setSymbol] = React.useState(initialMarket)
  const goSymbol = (symbol) => [window.localStorage.setItem('mode', 'trade'), window.location.href = `/${encodeURIComponent(symbol)}`]
  const size = useWindowSize() // size.width
  const [adsenseLoaded, setAdsenseLoaded] = React.useState(true)
  
  // 현재 접속한 마켓에 대한 정보를 저장하기
  let currentMarket = false, currentCoin = initialMarket && initialMarket !== 'CRYPTOCAP:TOTAL' ? initialMarket.replace('UPBIT:', '').replace('KRW', '') : false
  if (currentCoin) { currentMarket = marketCoins.find(coin => coin.market === `KRW-${currentCoin}`) }

  // 트레이딩모드에 대한 값 지정하기
  const lChartSet = window.localStorage.getItem('lChartSet') || 'basic'
  const lChartSet2 = window.localStorage.getItem('lChartSet2') || 'basic'
  const lChartSet3 = window.localStorage.getItem('lChartSet3') || 'basic'

  const urls = [
    { "href": "https://www.fmkorea.com/coin", "title": "에펨코리아 가상화폐", "emoji": "🔥" },
    { "href": "https://gall.dcinside.com/board/lists?id=bitcoins_new1", "title": "디시인사이드 비트코인 갤러리", "emoji": "👑" },
    { "href": "https://gall.dcinside.com/mgallery/board/lists/?id=coin", "title": "디시인사이드 알트 갤러리", "emoji": "🐣" },
    { "href": "https://www.ppomppu.co.kr/zboard/zboard.php?id=bitcoin", "title": "뽐뿌 가상화폐 게시판", "emoji": "📱" },
    { "href": "https://www.clien.net/service/board/cm_vcoin", "title": "클리앙 가상화폐당", "emoji": "💻" },
    { "href": "https://coinpan.com/", "title": "코인판 공식 웹사이트", "emoji": "🐋" },
    { "href": "https://kimpga.com/", "title": "김프가 김치 프리미엄 확인", "emoji": "🚨" },
    { "href": "http://mlbpark.donga.com/mp/b.php?search_select=sct&search_input=&select=spf&m=search&b=bullpen&query=%EC%BD%94%EC%9D%B8", "title": "MLBPARK 코인 게시판", "emoji": "⚾" },
    { "href": "https://www.instiz.net/name?category=102", "title": "인스티즈 익명잡담 코인방", "emoji": "🩰" },
    { "href": "https://cafe.naver.com/nexontv", "title": "비트맨 네이버 대표카페", "emoji": "☕" },
    { "href": "https://www.ddengle.com/", "title": "땡글닷컴 채굴 커뮤니티", "emoji": "⛏" },
    { "href": "https://quasarzone.com/bbs/qf_cryptocurrency", "title": "퀘이사존 채굴 공간", "emoji": "💻" },
    { "href": "https://kr.coinness.com/", "title": "코인니스 가상화폐 뉴스피드", "emoji": "📰" },
    { "href": "https://cobak.co.kr/news/home", "title": "코박뉴스 가상화폐 뉴스피드", "emoji": "📰" },
    { "href": "https://www.tokenpost.kr/breaking", "title": "토큰포스트 가상화폐 미디어", "emoji": "📰" },
    { "href": "https://www.blockmedia.co.kr/", "title": "블록미디어 가상화폐 미디어", "emoji": "📰" },
    { "href": "https://dstreet.io/", "title": "디스트리트 가상화폐 미디어", "emoji": "📰" },
    { "href": "https://www.ubcindex.com/indexes/IDX.UPBIT.UBMI", "title": "업비트 UBCI 시장 인덱스 분석", "emoji": "📈" },
    { "href": "https://coinmarketcap.com/ko/", "title": "코인마켓캡 전세계 거래소 차트", "emoji": "⚔" },
    { "href": "https://www.coingecko.com/ko", "title": "코인개코 암호화폐 통계", "emoji": "🐸" },
    { "href": "https://cryptoquant.com/overview/btc-exchange-flows", "title": "크립토퀀트 온체인 차트", "emoji": "🐳" },
    { "href": "https://glassnode.com/", "title": "Glassnode 온체인 차트", "emoji": "🚨" },
    { "href": "https://xangle.io/", "title": "쟁글 재단별 공시알림", "emoji": "🔎" },
    { "href": "https://coin360.com/", "title": "코인360 시총거래 토탈맵", "emoji": "🌎" },
    { "href": "https://btc.com/", "title": "BTC.COM 익스플로러", "emoji": "💎" },
    { "href": "https://etherscan.io/", "title": "이더스캔 익스플로러", "emoji": "💎" },
    { "href": "https://www.tokenterminal.com/", "title": "토큰터미널 디파이&랜딩", "emoji": "💰" },
    { "href": "https://www.bybt.com/", "title": "BYBT 선물만기 및 GBTC", "emoji": "🌨️" },
    { "href": "https://kr.investing.com/futures-expiration-calendar/", "title": "CME 선물만기 확인", "emoji": "📆" },
    { "href": "https://ko.coinjinja.com/events/time/this_week", "title": "Coinjinja STO/ICO 일정", "emoji": "🗃️" },
    { "href": "https://cryptorank.io/upcoming-ico", "title": "CRYPTORANK IDO/IEO 일정", "emoji": "📌" },
    { "href": "https://twitter.com/whale_alert", "title": "Whale Alert 고래지갑 이동알림", "emoji": "🐳" },
    { "href": "https://t.me/s/BinanceLiquidations", "title": "바이낸스 선물알림 청산 알림", "emoji": "💥" },
    { "href": "https://t.me/DropTheBitt", "title": "드랍더비트 트윗 자동한글번역", "emoji": "🌏" },
    { "href": "https://t.me/mbmpostcom", "title": "MBMPOST 호재·악재·속보", "emoji": "🔥" },
    { "href": "https://t.me/enjoymyhobby", "title": "취미생활방 가상화폐 소식방", "emoji": "🍹" },
    { "href": "https://t.me/coinkokr", "title": "코인코 중요공지 알림방", "emoji": "🪂" },
    { "href": "https://t.me/shrimp_notice", "title": "새우잡이어선 중요공지 알림방", "emoji": "🦐" },
    { "href": "https://t.me/xvgwhitedog2", "title": "하양이 아빠 코인뉴스 정리", "emoji": "🐶" },
    { "href": "https://t.me/Ugh_HH", "title": "최저시급 김으악 코인뉴스 소식방", "emoji": "👨‍🚀" },
    { "href": "https://t.me/dogeland01", "title": "코인갤러리 비트코인 시황분석방", "emoji": "🐮" },
    { "href": "https://t.me/WeCryptoTogether", "title": "코인같이투자 정보대화방", "emoji": "🙌" },
    { "href": "https://www.youtube.com/channel/UC3w3gZSKAWZ-nSvWEfQsMzw", "title": "나스닥다우존스 MACD 공부 유튜브", "emoji": "🏹" },
    { "href": "https://www.youtube.com/channel/UC9KQaCA_EMobJUxZszQ4wlg", "title": "박호두 비트코인 선물 유튜브", "emoji": "🌝" },
    { "href": "https://www.youtube.com/channel/UCnXe6v0-5vmMMRU2qx0XwUw", "title": "사또 비트코인 선물 유튜브", "emoji": "🦸‍♂️" },
    { "href": "https://www.youtube.com/channel/UCMdI0q61jS3Ni2URTNZiC4A", "title": "김단타 비트코인 선물 유튜브", "emoji": "🍙" }
  ]

  return (
    <Page>
      <Helmet>
        {currentMarket && mode === 'trade'
          ? <title>{currentMarket.korean_name}({currentMarket.english_name}, {currentCoin}) 차트 - COINRBI</title>
          : null}
        {mode === 'static'
          ? <title>시장분석 - 코알(COINRBI</title>
          : null}
      </Helmet>

      <HomeHeader />

      <div style={{ padding: '0.75rem' }}>
        <Page.ChartButtons>
          <div>
            {currentMarket && (currentMarket.market.indexOf('KRW-BTC') < 0)
              ? (<a href="#updateChartSymbol" className={symbol.indexOf(`UPBIT:${currentCoin}KRW`) === 0 ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol(symbol)]}>🎯 {currentMarket.korean_name} <small>차트</small></a>)
              : null}

            <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:TOTAL' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:TOTAL')]}>🌎 시가총액</a>
            <a href="#updateChartSymbol" className={symbol === 'BINANCEUS:BTCUSDT' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BINANCEUS:BTCUSDT')]}>🍔 버거대장</a>
            <a href="#updateChartSymbol" className={symbol === 'UPBIT:BTCKRW' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('UPBIT:BTCKRW')]}>🥓 김치대장</a>
            <a href="#updateChartSymbol" className={symbol === 'BITFINEX:BTCUSDSHORTS/BITFINEX:BTCUSDLONGS' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITFINEX:BTCUSDSHORTS/BITFINEX:BTCUSDLONGS')]}>🪓 롱숏</a>
            <a href="#updateChartSymbol" className={symbol === 'UPBIT:BTCKRW/BITHUMB:BTCKRW' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('UPBIT:BTCKRW/BITHUMB:BTCKRW')]}>🥊 업비트/빗썸</a>
            <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:BTC.D' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:BTC.D')]}>🍕 비트도미</a>
            <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:USDT.D' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:USDT.D')]}>🔥 테더도미</a>
            <a href="#updateChartSymbol" className={symbol === 'CRYPTOCAP:OTHERS.D' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('CRYPTOCAP:OTHERS.D')]}>🌱 알트도미</a>
            <a href="#updateChartSymbol" className={symbol === 'FOREXCOM:NSXUSD' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('FOREXCOM:NSXUSD')]}>🥰 나스닥테크탑100</a>
            <a href="#updateChartSymbol" className={symbol === 'BITFINEX:BTCUSDLONGS' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITFINEX:BTCUSDLONGS')]}>🌋 롱</a>
            <a href="#updateChartSymbol" className={symbol === 'BITFINEX:BTCUSDSHORTS' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITFINEX:BTCUSDSHORTS')]}>🌊 숏</a>
            <a href="#updateChartSymbol" className={symbol === 'BITMEX:XBTUSD' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('BITMEX:XBTUSD')]}>🐋 비맥 XBT</a>
            <a href="#updateChartSymbol" className={symbol === 'TSX:BITI' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('TSX:BITI')]}>🧛‍♂️ BITI</a>
            <a href="#updateChartSymbol" className={symbol === 'FX_IDC:USDKRW' ? 'active' : ''} onClick={e => [e.preventDefault(), goSymbol('FX_IDC:USDKRW')]}>💸 환율</a>
          </div>
        </Page.ChartButtons>
      </div>

      {mode === 'trade' && (symbol.indexOf('/') < 0) && symbol.indexOf('UPBIT:') === 0 && currentMarket ? <TableWidget pick={currentMarket} /> : null}
      
      {mode === 'trade' ? (
        <Page.Grid>
          <div
            className="animate__animated animate__fadeInLeft"
            style={{ animationDelay: `${2}s`, width: (symbol.indexOf('/') < 0) ?'830px' : '100%', height: '700px' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a
                href="#lChartSet"
                onClick={e => {
                  window.localStorage.setItem('lChartSet', 'basic')
                  return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                }}
                style={lChartSet === 'basic' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
              >
                MACD
              </a>
              <a
                href="#lChartSet"
                onClick={e => {
                  window.localStorage.setItem('lChartSet', 'rsi')
                  return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                }}
                style={lChartSet === 'rsi' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
              >
                RSI
              </a>
              <a
                href="#lChartSet"
                onClick={e => {
                  window.localStorage.setItem('lChartSet', 'wr')
                  return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                }}
                style={lChartSet === 'wr' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
              >
                W%R
              </a>
              <a
                href="#lChartSet"
                onClick={e => {
                  window.localStorage.setItem('lChartSet', 'obv')
                  return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                }}
                style={lChartSet === 'obv' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
              >
                OBV
              </a>
              <a
                href="#lChartSet"
                onClick={e => {
                  window.localStorage.setItem('lChartSet', 'all')
                  return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                }}
                style={lChartSet === 'all' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
              >
                ALL
              </a>
            </div>
            <iframe src={`/chart?name=${symbol}&interval=${(symbol.indexOf('/') < 0) ?'240' : '1D'}&width=${(symbol.indexOf('/') < 0) ? `830` : `1680`}&height=600&chartSet=${lChartSet}&hiddenSidebar=false`} height={610} width={(symbol.indexOf('/') < 0) ? 830 : `100%`} frameBorder={0} />
          </div>
        
          {symbol !== 'CRYPTOCAP:TOTAL' && (symbol.indexOf('/') < 0) ? (
            <>
              <div className="animate__animated animate__fadeInRight" style={{ animationDelay: `${2}s`, width: '830px', height: '700px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    href="#lChartSet2"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet2', 'basic')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet2 === 'basic' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    MACD
                  </a>
                  <a
                    href="#lChartSet2"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet2', 'rsi')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet2 === 'rsi' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    RSI
                  </a>
                  <a
                    href="#lChartSet2"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet2', 'wr')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet2 === 'wr' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    W%R
                  </a>
                  <a
                    href="#lChartSet2"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet2', 'obv')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet2 === 'obv' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    OBV
                  </a>
                  <a
                    href="#lChartSet2"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet2', 'all')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet2 === 'all' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    ALL
                  </a>
                </div>
                <iframe src={`/chart?name=${symbol}&interval=1&width=830&height=600&chartSet=${lChartSet2}&hiddenSidebar=false`} height={610} width={830} frameBorder={0} />
              </div>
            </>
          ) : null}
          
          {symbol === 'CRYPTOCAP:TOTAL' ? (
            <>
              <div className="animate__animated animate__fadeInRight" style={{ animationDelay: `${2}s`, width: '830px', height: '700px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    href="#lChartSet3"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet3', 'basic')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet3 === 'basic' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    MACD
                  </a>
                  <a
                    href="#lChartSet3"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet3', 'rsi')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet3 === 'rsi' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    RSI
                  </a>
                  <a
                    href="#lChartSet3"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet3', 'wr')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet3 === 'wr' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    W%R
                  </a>
                  <a
                    href="#lChartSet3"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet3', 'obv')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet3 === 'obv' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    OBV
                  </a>
                  <a
                    href="#lChartSet3"
                    onClick={e => {
                      window.localStorage.setItem('lChartSet3', 'all')
                      return [e.stopPropagation(), e.preventDefault(), window.location.reload()]
                    }}
                    style={lChartSet3 === 'all' ? { fontWeight: '900', color: 'yellow', padding: '0.5rem 0.75rem' } : { padding: '0.5rem 0.75rem' }}
                  >
                    ALL
                  </a>
                </div>
                <iframe src={`/chart?name=BINANCEUS:BTCUSDT&interval=1&width=830&height=600&chartSet=${lChartSet3}&hiddenSidebar=false`} height={610} width={830} frameBorder={0} />
              </div>
            </>
          ) : null}
        </Page.Grid>
      ) : null}
      
      {mode === 'trade' ? <MiniChartsWidget /> : null}
      
      {mode === 'trade'
        ? (
            <div style={{ width: '1200px', minWidth: '680px', maxWidth: '100%', minHeight: '300px', height: '300px', margin: '1rem auto' }}>
              <AdSense.Google client='ca-pub-2415806529829125' slot='6493897876' style={{ display: 'block' }} format='auto' responsive='true' />
            </div>
        )
        : null}

      {mode === 'trade'
        ? (
          <Page.Grid>
            <div style={{ flex: '1 1 100%' }}>
              <CrossWidgets
                handleCoin={(name, stay, event) => {
                  window.localStorage.setItem('mode', 'trade')
                  if (stay === 'state') {
                    setSymbol(name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`)
                  } else if (stay === 'href' && event) {
                    event.target.href = `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
                  } else {
                    window.location.href= `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
                  }
                }}
              /> 
            </div>
          </Page.Grid>
          )
          : null}
      
      {mode === 'trade'
        ? (
          <DashboardWidget
              market={initialMarket}
              isWide={size.width * 1 >= 2000}
              handleCoin={(name, stay, event) => {
                window.localStorage.setItem('mode', 'trade')
                if (stay === 'state') {
                    setSymbol(name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`)
                } else if (stay === 'href' && event) {
                  event.target.href = `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
                } else {
                  window.location.href= `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
                }
              }}
            />
        )
        : null}

      {mode === 'trade'
        ? (
          <RbiTreeWidget 
            handleCoin={(name, stay, event) => {
              window.localStorage.setItem('mode', 'trade')
              if (stay === 'state') {
                setSymbol(name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`)
              } else if (stay === 'href' && event) {
                event.target.href = `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
              } else {
                window.location.href= `/${name ? `UPBIT:${name}KRW` : `CRYPTOCAP:TOTAL`}`
              }
            }}
          />
        )
        : null}
      
      {mode === 'static' && symbol === 'CRYPTOCAP:TOTAL' ? (
        <>
          <Page.Grid>
            <Block style={{ flex: '1 1 100%', padding: '1rem' }}>
              <div style={{ marginBottom: '2rem' }}>
                <iframe src="https://coin360.com/widget/map.html?utm_source=embed_map" frameborder="0" title="Coin360.com: Cryptocurrency Market State" width="100%" height="360"></iframe>
              </div>
              <iframe src={`/hitmap.html`} height={400} width="100%" frameBorder={0} />
            </Block>
            <Block style={{ minWidth: '420px', maxWidth: '420px' }}>              
              <div style={{ width: '420px' }}>
                {adsenseLoaded ? <AdSense.Google client='ca-pub-2415806529829125' slot='5872438584' style={{ display: 'block', width: 420 }} format='rectangle' /> : null}
              </div>
              
              <div style={{ height: '360px', margin: '1.5rem 0', overflowY: 'auto' }}>
                {urls.map((url, key) => {
                  return (
                    <div style={{ padding: '0.25rem 0' }} key={`url-${key}`}>
                      <a href={url.href} title={url.title} target="_blank">{url.emoji} {url.title}</a>
                    </div>
                  )
                })}
              </div>
            </Block>
            
          </Page.Grid>
            
        </>
      ) : null}

    </Page>
  )
}

export default Home
