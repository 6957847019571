import React from 'react'
import qs from 'query-string'
import { AdvancedChart } from "react-tradingview-embed"

const Chart = () => {
  const { name, interval, width, height, chartSet, hiddenSidebar } = qs.parse(window.location.search)
  const symbol = name || 'CRYPTOCAP:TOTAL'

  let studies = ["MACD@tv-basicstudies", "BB@tv-basicstudies", "MASimple@tv-basicstudies", "ZigZag@tv-basicstudies", "LinearRegression@tv-basicstudies"]
  if (chartSet && chartSet === 'rsi') { studies.push("StochasticRSI@tv-basicstudies") }
  if (chartSet && chartSet === 'obv') { studies.push("OBV@tv-basicstudies") }
  if (chartSet && chartSet === 'wr') { studies.push("WilliamR@tv-basicstudies") }
  if (chartSet && chartSet === 'all') { studies.push("StochasticRSI@tv-basicstudies", "OBV@tv-basicstudies", "WilliamR@tv-basicstudies") }

  return (
    <AdvancedChart
      widgetProps={{
        "width": width || 580, "height": height || 700,
        "symbol": symbol, "interval": interval || 1, "range": interval === 1 ? "1D" : "30D",
        "timezone": "Asia/Seoul", "theme": "dark", "style": "8",
        "locale": "kr", "toolbar_bg": "#f1f3f6", "enable_publishing": false,
        "hide_side_toolbar": hiddenSidebar === 'true' ? true : false, "studies": studies,
        "show_popup_button": true, "popup_width": "1200", "popup_height": "850", "save_image": false,
        "Page_id": "tradingview_dd5e3"
      }}
    />
  )

}

export default Chart
