import React from 'react'
import styled from 'styled-components'

import Typed from 'react-typing-effect'

import moment from 'moment'
import 'moment/locale/ko'

const comma = (x) => {
  if (!x || x < 1) { return x }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Notice = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0; margin: 0.35rem 0.75rem; background: transparent;  color: white;
  text-overflow: ellipsis; overflow: hidden; min-height: 24px; white-space: nowrap;
`

Notice.Item = styled.a`
  position: relative; box-sizing: border-box;
  display: block; transition: all 0.3s;
  font-weight: 500; text-decoration: none; color: white;
  &:hover { color: #00FFFF; text-decoration: underline; }
`

class TimelineWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = { items: [] }

    this.timer = false
    this.initialize = this.initialize.bind(this)
    this.loadRSS = this.loadRSS.bind(this)
    this.run = this.run.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async loadRSS() {
    const that = this
    return await fetch('https://api.coinrbi.com/timeline')
      .then((response) => response.json().catch(e => { }))
      .then((datas) => {
        that.setState({ items: datas || [] })
      })
      .catch(e => {
        console.log(`타임라인 데이터를 불러오지 못했습니다.`)
      })
  }

  async initialize() {
    const that = this
    await that.loadRSS()
    this.timer = window.setInterval(that.run, 3000 * 10)
  }

  async run() {
    const that = this
    await that.loadRSS()
  }

  componentWillUnmount() {
    if (this.timer) { window.clearInterval(this.timer) }
  }

  render() {
    let items = (this.state.items || []).slice(0, 1)

    const filterCoins = this.props.coins || []
    if (filterCoins && filterCoins.length) {
      items = items.filter(item => {
        if (!item.coins) { return false }
        const coins = item.coins || []
        const symbols = coins.map(coin => coin.name)
        return filterCoins.map((filterCoin) => symbols.includes(filterCoin)).includes(true)
      })
    }

    return (
      <Notice>
        {items.map((item, idx) => {
          const mentions = item.title.split(',')
          if (!mentions.length) { return null }

          let coins = item.coins || []
          if (filterCoins && filterCoins.length) {
            coins = coins.filter(coin => {
              return filterCoins.includes(coin.name)
            })
          }
          const upCoins = coins.filter(coin => coin.stance === 'up')
          const downCoins = coins.filter(coin => coin.stance === 'down')
          const totalVolume = coins.reduce((s, coin) => s + coin.totalTradeAmount, 0)

          const sentances = [
            `🦄 ${mentions[0]}, 업비트 기준`, 
            `⏰ ${mentions[1]}의 크로스 발생`,
            `🟢 ${upCoins.length}개 상방 🔴 ${downCoins.length}개 하방 크로스 발생`,
            `🐳 총 거래볼륨은 ${comma(totalVolume)}원 발생된 상태입니다.`
          ]

          const typedProps = {
            typingDelay: 100, speed: 50, eraseSpeed: 10,
            text: sentances, eraseDelay: 1618
          }

          return (
            <Notice.Item key={idx} href={`${item.link}?ref=timeline`}>
              <Typed {...typedProps} />
            </Notice.Item>
          )
        })}
      </Notice>
    )
  }
}

export default TimelineWidget