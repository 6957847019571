import React from 'react'
import styled from 'styled-components'
import { MiniChart } from "react-tradingview-embed"

const Box = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem;
`

Box.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: flex;
`

const MiniChartsWidget = () => {
  const tickers = ['CRYPTOCAP:BTC.D' , 'CRYPTOCAP:OTHERS.D', 'BTCUSDLONGS' , 'BTCUSDSHORTS' , 'FX_IDC:USDKRW', 'FOREXCOM:NSXUSD']
   
  return (
    <Box.Group>
      {tickers.map((ticker, mIdx) => {
        const widgetProps =   {
          "symbol": ticker,
          "width": 264, "height": 161,
          "locale": "kr",
          "dateRange": "12M",
          "colorTheme": "dark",
          "trendLineColor": "rgba(0, 255, 255, 1)",
          "underLineColor": "rgba(0, 255, 255, 1)",
          "underLineBottomColor": "rgba(73, 133, 231, 0)",
          "isTransparent": true,
          "autosize": false,
          "largeChartUrl": "",
          "chartOnly": false
        }
        return (
          <Box key={mIdx}>
            <MiniChart  widgetProps={widgetProps} />
          </Box>
        )
      })}
    </Box.Group>
  )
}

export default MiniChartsWidget
