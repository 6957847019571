import React from 'react'
import styled from 'styled-components'
import twitters from '../twitters'
import markets from '../markets'
// import { Timeline as TwitterTimeline } from 'react-twitter-widgets'

import _ from 'lodash'
import ReactGA from 'react-ga'
import qs from 'query-string'

import moment from 'moment'
import 'moment/locale/ko'

const comma = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); }


const Widget = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%;
`

Widget.Body = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; flex-wrap: wrap; justify-content: center;
  padding: 1rem 0;
`

Widget.Box = styled.div`
  position: relative; box-sizing: border-box;
  margin: 1rem; width: 300px; height: 600px;
`

const TwittersWidget = ({ meterials, loop }) => {
  const [keeps, setKeeps]  = React.useState({ items: [], msg: '' })
  const rank = meterials.sort((a, b) => {
    return (
      a.totalTradeAmount > b.totalTradeAmount
    ) ? 1 : -1
  }).sort((a, b) => {
    return (
      a.score > b.score
    ) ? 1 : -1
  })

  const coins = rank.map(({ market }) => market)

  const lines = twitters
    .filter(twitter => {
      if (!loop) { return true }
      const isExists = coins.find(coin => coin === twitter.market)
      console.log({ name: twitter.market, isExists })
      return isExists
    })
    .map(twitter => {
      twitter.coin = coins.find(coin => coin === twitter.market)
      twitter.coinRank = coins.findIndex(coin => coin === twitter.market)
      twitter.score = twitter.coinRank * 3 + (coins.includes(twitter.market) ? 1 : 0)
      return twitter
    })

  lines.sort((a, b) => a.score < b.score ? 1 : -1)

  return (
    <>
      <Widget>
        <Widget.Body>
          {lines
            .map((twitter, idx) => {
            const name = twitter.market.replace('KRW-', '')
            const market = markets.find(({ market }) => market === twitter.market) || {}
            return (
              <Widget.Box key={idx}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem' }}>
                  <div>
                    <img src={`https://api.coinrbi.com/symbols/${name}.png`} style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '1rem' }} />
                  </div>
                  <div>
                    <span
                      style={{
                        color: keeps.items.find(item => item.market === twitter.market) ? 'yellow' : 'inherit',
                        fontWeight: keeps.items.find(item => item.market === twitter.market) ? '900' : '300',
                        cursor: 'pointer'
                      }}
                      onClick={(e) => {
                        const prev = (keeps.items || []).find(item => item.market === twitter.market)
                        if (prev) {
                          const next = keeps.items.filter(item => item.market !== twitter.market)
                          const msg = next.map(keep => {
                            const name = keep.market.replace('KRW-', '')
                            return `${keep.meta.korean_name}(${name} 코인)`
                          }).join(`, `)

                          setKeeps({ msg, items: next })
                        } else {
                          const next = [...keeps.items, { ...twitter, meta: market }]
                          const msg = next.map(keep => {
                            const name = keep.market.replace('KRW-', '')
                            return `${keep.meta.korean_name}(${name} 코인)`
                          }).join(`, `)
                          setKeeps({ msg, items: next })
                        }
                      }}
                    >
                      <strong>{market.korean_name || '미지정'}</strong> <small>{name}</small>
                    </span>
                  </div>
                </div>
                <a className="twitter-timeline" data-lang="ko" data-width="300" data-height="550" data-theme="dark" href={`https://twitter.com/${twitter.twitter}`}></a> 
              </Widget.Box>
            )
          })}
        </Widget.Body>
        <div style={{  textAlign: 'center', padding: '1rem' }}>
          <textarea
            id="textA"
            defaultValue={(keeps && keeps.msg) || ''}
            placeholder={`코인의 이름을 누르시면 코인이름을 메모할 수 있습니다.`}
            style={{ width: '800px', maxWidth: '80%', height: '200px', padding: '1rem' }}
          />
        </div>
      </Widget>
    </>
  )
}

class DashboardWidget extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()
    
    const query = qs.parse(window.location.search) || {}
    this.state = { at: new Date(), started: false, loop: props.loop || query.loop === 'true', current: {}, markets: [], data: {}, prevData: {} }

    this.timer = false
    this.initialize = this.initialize.bind(this)
    this.run = this.run.bind(this)
  }
  
  componentDidMount() {
    this.initialize()
  }
  
  componentDidUpdate() {
    if (this.timer) { window.clearInterval(this.timer) }
  }

  async initialize() {
    const that = this
    // 모든 코인 리스트 배열 /markets.json
    await fetch(`/markets.json`, { signal: this.abortController.signal })
      .then((response) => response.json().catch(e => []))
      .then(async (markets) => {
        await new Promise((r) => that.setState({ markets }, r))
        if (!that.state.started) { that.run() }
        if (that.state.loop) { window.setInterval(that.run, 1000 * 60) }
      })
      .catch(e => {})
  }

  // 상황판에 표현할 데이터를 가져오는 장치
  async run() {
    const that = this, at = new Date(), mt = moment(at), prevMinMt = moment(at).add(-1, 'minute')
    const mtFormat = mt.format('YYYYMMDDHHmm')
    const prevMinFormat = prevMinMt.format('YYYYMMDDHHmm')

    return await fetch(`https://api.coinrbi.com/datas/${mtFormat}`)
      .then((response) => response.json().catch(e => null))
      .then(async (data) => {
        if (!data) { return await fetch(`https://api.coinrbi.com/datas/${prevMinFormat}`).then((response) => response.json().catch(e => null)) }
        return data
      })
      .then(async (data) => {
        if (!data) { return }
        const next = { prevData: JSON.parse(JSON.stringify(that.state.data)), data, started: true }
        ReactGA.event({ category: 'Response', action: `Live MACD Download`, label: `MACD Download` })
        that.setState(next)
      })
      .catch(e => { return })
  }

  render() {
    const that = this
    const { started, data, prevData, loop } = that.state
    if (!started) { return null }
    
    ReactGA.event({ category: 'AutoTwitter', action: `Coin Twitter Preview`, label: `Coin Auto Twitter` })
    const prevMaterials = _.get(prevData, 'meterials') || []
    const meterials = data ? (data.meterials || prevMaterials || []) : []
    return <TwittersWidget meterials={meterials} loop={loop} />
  }

  componentWillUnmount() {
    this.abortController.abort()
    if (this.timer) { window.clearInterval(this.timer) }
  }
}

export default DashboardWidget